import { useLazyQuery, useMutation } from '@apollo/client';
import _, { cloneDeep } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import CustomAuth from '../common/auth/CustomAuth';
import { UserContext } from '../common/auth/UserContext';
import { constants } from '../common/constants';
import { FormProvider } from '../common/form/FormContext';
import FormModal from '../common/form/FormModal';
import { EMPTY_SCHEDULE } from '../common/form/widgets/ScheduleWidget';
import Nav from '../common/nav/Nav';
import useHistory from '../common/utils/useHistory';
import { ValidationRulesType } from '../production/helpers/productionUtils';
import { getDateRangeValidators } from '../supply/booking/ScheduleCalculator';
import { clientWithoutAuth, inquiry, saveInquiryResponse } from '../supply/opportunity/opportunity';
import InquiryPreview from './InquiryPreview';
import InquirySuccess from './InquirySuccess';
import { setToken as setLocalStorageToken } from '../common/auth/api';

export default () => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const [values, setValues] = useState({
        availability: undefined
    });
    const [isPreview, setIsPreview] = useState(true);
    const [thankYouVisible, setThankYouVisible] = useState(false);
    const [token, setToken] = useState('');
    const { searchParams } = useHistory();

    const inquiryId = searchParams.get('inquiryId');
    const username = searchParams.get('username');

    const [getInquiry, { data, loading }] = useLazyQuery(inquiry, {
        client: clientWithoutAuth,
        fetchPolicy: constants.apolloFetchPolicy,
        notifyOnNetworkStatusChange: true
    });

    const [save, { loading: saving }] = useMutation(saveInquiryResponse, {
        client: clientWithoutAuth,
        context: {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    });

    const validationRules: ValidationRulesType = useMemo(() => {
        return {
            availability: [
                {
                    isValid: (prop, state, isModified, resolveI18nToken) => {
                        const value = _.get(state, prop);
                        return !!value ? true : resolveI18nToken('validation.value.required');
                    },
                    isRequired: true
                }
            ]
        };
    }, [values]);

    useEffect(() => {
        if (token) {
            getInquiry({
                variables: { id: inquiryId, organizationId: activeOrganizationAccount },
                context: {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            });
        }
        setLocalStorageToken(token);
    }, [token]);

    return (
        <>
            <Nav />
            {token ? (
                <FormProvider state={values} setState={setValues} validationRules={validationRules}>
                    {isPreview ? (
                        <FormModal
                            id="inquiryResponse"
                            title=""
                            isOpen={true}
                            hideDefaultButtons={thankYouVisible}
                            isSaving={saving}
                            isLoading={loading}
                            onSave={() => {
                                save({
                                    variables: {
                                        inquiryResponse: {
                                            inquiryId: inquiryId,
                                            status: values.availability.id
                                        }
                                    }
                                })
                                    .then(() => {
                                        setIsPreview(false);
                                        setThankYouVisible(true);
                                    })
                                    .catch(() => {});
                            }}
                        >
                            <InquiryPreview data={data?.inquiry} />
                        </FormModal>
                    ) : (
                        <></>
                    )}
                    {thankYouVisible ? (
                        <InquirySuccess name={data?.inquiry?.inquiry?.contacts?.[0]?.name?.firstName} />
                    ) : (
                        <></>
                    )}
                </FormProvider>
            ) : (
                <>
                    <CustomAuth
                        username={username}
                        setBearer={(token) => {
                            setToken(token);
                        }}
                    />
                </>
            )}
        </>
    );
};
