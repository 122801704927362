import React, { useContext } from 'react';
import { Typography } from '@mui/material';
import _ from 'lodash';
import { InquiryInfo } from 'sr-types/lib/opportunity/v1/graphql';
import FormItem from '../common/form/FormItem';
import FormWidget from '../common/form/FormWidget';
import { V } from '../common/Layout';
import { InquiryPreviewInfo } from './form/InvitePreview';
import I18n from '../common/i18n/I18n';
import { FormContext } from '../common/form/FormContext';
import ScheduleWidget from '../common/form/widgets/ScheduleWidget';

type InquiryPreviewType = {
    data: InquiryInfo;
};

export default ({ data }: InquiryPreviewType) => {
    const { inquiry, personalizedMessage } = data || {};
    const { state } = useContext(FormContext);

    return inquiry ? (
        <V sx={{ height: '100%', px: 4, py: 1 }}>
            <V sx={{ gap: 2.5, justifyContent: 'start' }}>
                <Typography>
                    <I18n token="form.inquiryResponse.hi" />{' '}
                    {data.inquiry.name.substring(0, data.inquiry.name.indexOf(' '))},
                </Typography>

                <InquiryPreviewInfo
                    productionName={inquiry.production.name}
                    orgName={inquiry.organization.name}
                    role={inquiry.productionRequirements?.[0]?.role}
                    summary={inquiry.summary.about}
                    dates={inquiry.opportunityPeriod}
                    locations={inquiry.productionRequirements?.[0]?.locations}
                    personalizedMessage={personalizedMessage}
                    productionType={inquiry.production?.type}
                />

                <Typography sx={{ fontSize: 'medium' }}>
                    <I18n token="form.inquiryResponse.availability" />
                </Typography>

                <FormItem>
                    <FormWidget
                        component="Chips"
                        name={'availability'}
                        options={[
                            { id: 'Available', label: <I18n token="dialog.yes" /> },
                            { id: 'NotAvailable', label: <I18n token="dialog.no" /> }
                        ]}
                    />
                </FormItem>
            </V>
            <Typography fontSize="small">
                <I18n
                    token="form.inquiryResponse.responseVisibility"
                    values={{ name: inquiry.contacts?.[0]?.name?.firstName }}
                />
            </Typography>
        </V>
    ) : (
        <></>
    );
};
