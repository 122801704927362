import React, { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { Filter } from '../../common/components/types';
import { FormContext } from '../../common/form/FormContext';
import FormItem from '../../common/form/FormItem';
import FormPanel from '../../common/form/FormPanel';
import FormWidget from '../../common/form/FormWidget';
import OrganizationOptionRenderer from '../../organization/OrganizationOptionRenderer';
import PersonOptionRenderer from './PersonOptionRenderer';

export enum LineItemTypeEnum {
    person = 'Person',
    organization = 'Organization'
}

export type LineItemType = (typeof LineItemTypeEnum)[keyof typeof LineItemTypeEnum];

type ReactElementType = {
    [key in LineItemType]: (props) => JSX.Element;
};

const optionRenderer: ReactElementType = {
    [LineItemTypeEnum.person]: PersonOptionRenderer,
    [LineItemTypeEnum.organization]: OrganizationOptionRenderer
};

interface ShortlistLineItemsItemsProps {
    setIsSaveDisabled: Dispatch<SetStateAction<boolean>>;
    addLineItemFilter: Filter;
    onCreateHandle: (e) => void;
    lineItemEntityType?: LineItemType;
    alreadyAddedIs: string[];
    showAutocomplete?: boolean;
    inquiry: boolean;
}

export default ({
    setIsSaveDisabled,
    addLineItemFilter,
    onCreateHandle,
    lineItemEntityType = undefined,
    alreadyAddedIs = [],
    showAutocomplete = true,
    inquiry = false
}: ShortlistLineItemsItemsProps) => {
    const { validation } = useContext(FormContext);

    useEffect(() => {
        setIsSaveDisabled(validation && !validation.isValid);
    }, [validation]);

    return (
        <FormPanel>
            <FormItem>
                {showAutocomplete &&
                (lineItemEntityType === LineItemTypeEnum.person ||
                    lineItemEntityType === LineItemTypeEnum.organization) ? (
                    <FormWidget
                        disabled={inquiry}
                        name="itemReference"
                        label="Name"
                        component="ReferenceAutocomplete"
                        addOption
                        entity={lineItemEntityType}
                        OptionRenderer={optionRenderer[lineItemEntityType]}
                        onCreateHandle={onCreateHandle}
                        getOption={(item) => {
                            return {
                                id: item.id,
                                name: item.name,
                                role: item.role,
                                label: item.name,
                                ref: { ...item.reference, label: item.name }
                            };
                        }}
                        preload={false}
                        filter={addLineItemFilter}
                        filterById={alreadyAddedIs}
                        sortBy={'hierarchicalCategories_ASC'}
                    />
                ) : (
                    <FormWidget name="name" label="Name" />
                )}
            </FormItem>
            <FormItem>
                <FormWidget
                    name="itemStatus"
                    label="Status"
                    component="MetadataAutocomplete"
                    metadataName="ShortlistItemStatus"
                    allowCreate
                />
            </FormItem>
            <FormItem>
                <FormWidget name="summary.about" label="About" component="TextArea" hideHelperText={true} />
            </FormItem>
        </FormPanel>
    );
};
