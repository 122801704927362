import { Box, Collapse, ListItemButton, Tooltip, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { cloneDeep, findIndex } from 'lodash';
import React, { useContext, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Conditional } from '../Layout';
import { UserContext } from '../auth/UserContext';
import { AllPermissions, getFeaturePermissions } from '../auth/api';
import { Icons } from '../icons/Icons';
import icon from '../logo/Showrunnr_logomark_CMYK.svg';
import useHistory from '../utils/useHistory';
import { isDrawerOpenAtom } from './AppDrawerAtoms';
import { RouteType } from './AppRoute';
import { PLANNING } from './apps';

const itemHeight = '46px';

const OptionalLabel = ({ isOpen, item, expanded }) => {
    return isOpen ? (
        <React.Fragment>
            <ListItemIcon sx={{ justifyContent: 'center' }} color={item.disabled ? 'text.disabled' : 'text.primary'}>
                {item.icon && <item.icon />}
            </ListItemIcon>
            <ListItemText color={item.disabled ? 'text.disabled' : 'text.primary'} primary={item.label} />
            <Conditional condition={item.items}>
                <Box component={'div'} sx={{ mr: 1 }}>
                    {expanded ? <Icons.ExpandLess /> : <Icons.ExpandMore />}
                </Box>
            </Conditional>
        </React.Fragment>
    ) : (
        <Tooltip title={item.label} placement="right" arrow>
            <ListItemIcon sx={{ justifyContent: 'center' }} color={item.disabled ? 'text.disabled' : 'text.primary'}>
                {item.icon && <item.icon />}
            </ListItemIcon>
        </Tooltip>
    );
};

const getCurrentRoute = (routes, location) => {
    let currentRoute;
    outter: for (let i = 0; i < routes.length; i++) {
        const route: RouteType = routes[i];
        if (route.items) {
            for (let j = 0; j < route.items.length; j++) {
                const child: RouteType = route.items[j];
                if (child.link === location.pathname) {
                    currentRoute = child;
                    break outter;
                }
            }
        } else {
            if (route.link === location.pathname) {
                currentRoute = route;
                break;
            }
        }
    }
    return currentRoute;
};

const AppDrawerItem = ({ isOpen, item, paddingLeft, isSelected = false, handleClick, expanded }) => {
    return (
        <ListItemButton
            disabled={item.disabled}
            selected={isSelected}
            sx={{
                height: itemHeight,
                pl: isOpen ? paddingLeft : 0,
                pr: 0,
                justifyContent: isOpen ? 'left' : 'center'
            }}
            onClick={() => {
                if (!item.disabled) {
                    handleClick(item);
                }
            }}
        >
            <OptionalLabel
                isOpen={isOpen}
                item={item}
                expanded={expanded.has(item.label) && expanded.get(item.label) && isOpen}
            />
        </ListItemButton>
    );
};

export default ({ routes }) => {
    const { application, userProfile, activeOrganizationAccount } = useContext(UserContext);
    const [expanded, setExpanded] = useState(new Map<string, boolean>([]));
    const [isDrawerOpen, setIsDrawerOpen] = useRecoilState(isDrawerOpenAtom);
    const { location } = useHistory();

    const { changeRoute } = useHistory();

    const handleClick = (item: RouteType) => {
        if (item) {
            if (item.items && item.items.length) {
                // If item has children...
                const exp = cloneDeep(expanded);
                if (!isDrawerOpen) {
                    // If drawer is not open, open it.
                    setIsDrawerOpen(true);
                    if (!exp.get(item.label)) {
                        // If item is not expanded, expand it.
                        exp.set(item.label, true);
                    }
                } else {
                    // Otherwise just expand/collapse the group.
                    exp.set(item.label, !exp.get(item.label));
                }
                setExpanded(exp);
            } else {
                // If item is a leaf, close drawer and process click.
                setIsDrawerOpen(false);
                if (typeof item.onClick === 'function') {
                    item.onClick(item);
                } else if (typeof item.link === 'string') {
                    changeRoute(item.link, item.query);
                }
            }
        }
    };

    const listSx = { p: 0, pt: 1 };
    return (
        <Drawer
            id="app-drawer"
            sx={{
                m: 1,
                zIndex: 99,
                overflow: 'hidden',
                '.MuiListItemIcon-root': {
                    minWidth: '32px'
                },
                width: { xs: isDrawerOpen ? '100%' : '0', sm: isDrawerOpen ? '256px' : '64px' },
                display: { xs: isDrawerOpen ? 'flex' : 'none', sm: 'flex' }
            }}
            PaperProps={{
                elevation: 5,
                sx: { position: 'relative', borderRight: 'unset', borderRadius: 1, boxShadow: 'none' }
            }}
            ModalProps={{
                keepMounted: true // Better open performance on mobile.
            }}
            variant="permanent"
            anchor="left"
            open={true}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                <List sx={listSx}>
                    {routes.map((route: RouteType, index) => {
                        if (application.id === route.app.id) {
                            const parentPerms = getFeaturePermissions(
                                userProfile,
                                application,
                                activeOrganizationAccount,
                                route.feature
                            );
                            const children = [];
                            if (route.items && route.items.length) {
                                route.items.forEach((child: RouteType, childIndex) => {
                                    if (application.id === child.app.id) {
                                        const childPerms = getFeaturePermissions(
                                            userProfile,
                                            application,
                                            activeOrganizationAccount,
                                            child.feature
                                        );
                                        if (childPerms[AllPermissions.Read] && child.drawer) {
                                            children.push(
                                                <AppDrawerItem
                                                    key={`${index}.${childIndex}`}
                                                    paddingLeft={4}
                                                    item={child}
                                                    expanded={expanded}
                                                    isOpen={isDrawerOpen}
                                                    handleClick={handleClick}
                                                />
                                            );
                                        }
                                    }
                                });
                            }
                            if (
                                (parentPerms[AllPermissions.Read] ||
                                    (route.items && route.items.length > 0 && children.length > 0)) &&
                                route.drawer
                            ) {
                                const isSelected =
                                    route.link === location.pathname ||
                                    // route.link.indexOf(location.pathname) === 0 || // This will not work until routes paths are cleaned up.
                                    (location.pathname === PLANNING.home &&
                                        route.link === `${PLANNING.home}/workspace`) || // This is a temporary change and will be reverted when we add a home for planning
                                    findIndex(route.items, (child) => child.link === location.pathname) > -1;
                                return (
                                    <React.Fragment key={index}>
                                        <AppDrawerItem
                                            key={`${index}.group`}
                                            paddingLeft={2}
                                            item={route}
                                            isSelected={isSelected}
                                            expanded={expanded}
                                            isOpen={isDrawerOpen}
                                            handleClick={handleClick}
                                        />
                                        <Collapse
                                            in={expanded.has(route.label) && expanded.get(route.label) && isDrawerOpen}
                                            timeout="auto"
                                            unmountOnExit
                                        >
                                            {children}
                                        </Collapse>
                                        {route.divider ? <Divider key={`divider-${index}`} /> : <React.Fragment />}
                                    </React.Fragment>
                                );
                            } else {
                                return <React.Fragment key={index} />;
                            }
                        } else {
                            return <React.Fragment key={index} />;
                        }
                    })}
                </List>
                <List sx={listSx}>
                    <Divider key={'last'} />
                    <ListItemButton
                        sx={{ pt: 4, pb: 4, height: itemHeight }}
                        onClick={() => {
                            // window.open('', '_blank').focus();
                        }}
                    >
                        <img alt="Logo" style={{ width: '28px', height: '28px' }} src={icon} />
                        {isDrawerOpen && (
                            <>
                                <Typography sx={{ ml: 1 }} fontSize={'x-small'}>
                                    powered by
                                </Typography>
                                <Typography sx={{ ml: 0.5 }} fontSize={'large'}>
                                    showrunnr
                                </Typography>
                            </>
                        )}
                    </ListItemButton>
                </List>
            </Box>
        </Drawer>
    );
};
