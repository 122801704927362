import { ButtonGroup, Button, Popover, ClickAwayListener, MenuList, MenuItem, Tooltip } from "@mui/material";
import React, { useState, useRef, useMemo, useEffect } from "react";
import { FilterItemProps } from "./QuickFilterItem";
import { Icons } from '../../icons/Icons';

export default (props: FilterItemProps) => {

    const { filter, onFilterChanged, requiredFilters = [] } = props;
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const anchorRef = useRef<HTMLDivElement>(null);
    const isRequiredFilter = requiredFilters.includes(filter.id);

    const handleMenuItemClick = (option: string) => {
        if (!filter.originalOptions) {
            filter.originalOptions = filter.options;
        } 
        const filteredOptions = [...filter.originalOptions].filter((o) => o !== option);
        setOptions(filteredOptions);
        filter.values = [];
        filter.values[0] = option;
        filter.options = filteredOptions;
        onFilterChanged(filter);
        handleClose();
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };


    const handleClose = (event?: Event) => {
        if (event && anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    const handleClear = () => {
        handleClose();
        filter.values = [];
        setOptions(filter.originalOptions);
        onFilterChanged(filter);
    };

    useEffect(() => {
        setOptions([...filter.options]);
    }, [filter.options])

    const { hasValues, text } = useMemo(() => {
         const len = filter.values ? filter.values.length : 0;
         const hasValues = len > 0;
         const text = hasValues ? filter.values[0] : filter.label;
         return { hasValues, text };
    }, [filter.values]);

    return (
        <React.Fragment>
            <ButtonGroup
                color={hasValues ? 'active' : 'inactive'}
                variant={hasValues ? 'contained' : 'outlined'}
                ref={anchorRef}
                size={'small'}
            >
                <Tooltip title={hasValues ? `${filter.label}` : ''}>
                    <Button size={'small'} sx={{ borderRadius: '5px' }} onClick={handleToggle}>
                        <p style={{ margin: 0 }}>{text}</p>
                        {hasValues && !isRequiredFilter ? (
                            <Icons.Close style={{ paddingLeft: '4px' }} onClick={handleClear} />
                        ) : (
                            <></>
                        )}
                    </Button>
                </Tooltip>
            </ButtonGroup>
            <Popover
                disablePortal
                open={open}
                anchorEl={anchorRef.current}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                sx={{
                    mt: 0.5
                }}
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                        id={`filter-${filter.id}-menu`}
                        autoFocusItem
                        sx={{
                            // pr: 2
                            maxHeight: '320px',
                            // this is added as per the input from @jay(UX)
                            maxWidth: '296px'
                        }}
                    >
                        {options.length > 0 ? 
                          (options.map((option, index) => (
                            <MenuItem
                                key={index}
                                sx={{ py: 1, pl: 1, pr: 2 }}
                                onClick={() => handleMenuItemClick(option)}
                            >
                                {option}
                            </MenuItem>
                        ))): (
                             <MenuItem disabled>
                                No options
                            </MenuItem>
                        )
                    }
                    </MenuList>
                </ClickAwayListener>
            </Popover>
        </React.Fragment>
    );

};
    