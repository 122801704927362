import React, { useContext, useState } from 'react';
import Validator, { ValidationState } from '../form/Validator';
import OkCancel from '../components/OkCancel';
import { logIn, LoginRequest, registerFlow, respondToChallenge } from './api';
import { AuthFormHeader, ForgotPassword } from './AccountLinks';
import I18n, { I18nContext, I18nContextType, useI18n } from '../../common/i18n/I18n';
import { Box, Container, IconButton, InputAdornment, Link, Paper, Typography } from '@mui/material';
import { FormContainer } from '../form/FormContainer';
import FormItem from '../form/FormItem';
import FormWidget from '../../common/form/FormWidget';
import { handleAndCommitChange } from '../form/forms';
import Challenge from './Challenge';
import { enqueueSnackbar } from '../components/Toast';
import { V } from '../Layout';
import { Icons } from '../icons/Icons';
import useHistory from '../utils/useHistory';
import { useLocation } from 'react-router-dom';

const validationRules = {
    username: [Validator.RULES.isRequired, Validator.RULES.email],
    password: [Validator.RULES.isRequired]
};

export default ({ username = '', onLogin, cancel }) => {
    const [challenge, setChallenge] = useState();
    const [session, setSession] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const i18nContext: I18nContextType = useContext(I18nContext);
    const location = useLocation();
    const email = location.state?.email || '';
    const validator = new Validator(validationRules, {
        // If true validator will keep going even after first field fails validation.
        runAll: false,
        reportUnmodified: true,
        i18nContext: i18nContext
    });

    const [values, setValues] = useState<LoginRequest>({
        username: username,
        password: ''
    });

    const [valid, setValid] = useState<ValidationState>({
        isValid: false,
        errors: {}
    });

    const [isBusy, setIsBusy] = useState(false);

    const onChange = (name, value) => {
        handleAndCommitChange(values, name, value, validator, setValues, setValid);
    };

    // TODO need to fix LoginResponse and start using it.
    const loginSuccess = (res) => {
        if (res['challenge']) {
            setChallenge(res['challenge']);
            setSession(res['session']);
            setIsBusy(false);
        } else {
            onLogin(res['idToken']);
        }
    };

    const loginFailed = (err) => {
        console.error('login error:', err);
        setIsBusy(false);
        enqueueSnackbar(`Login failed: ${err.message}`, { variant: 'error' });
    };

    const login = () => {
        setIsBusy(true);
        logIn(values).then(loginSuccess).catch(loginFailed);
    };

    const submit = (values) => {
        setIsBusy(true);
        respondToChallenge({
            ...values,
            challenge: challenge,
            session: session
        })
            .then(loginSuccess)
            .catch(loginFailed);
    };

    const hasErrors = (fieldName) => {
        return Array.isArray(valid.errors[fieldName]) && valid.errors[fieldName].length > 0;
    };

    let formErrors = [];
    if (valid.errors.length) {
        Object.keys(valid.errors).map((key) => {
            if (Array.isArray(valid.errors[key])) {
                formErrors = formErrors.concat(valid.errors[key]);
            }
        });
        console.warn('Form errors:', formErrors);
    }

    const keyboardEventHandler = (event) => {
        if (event.key === 'Enter' && valid.isValid) {
            login();
        }
    };

    const title = useI18n('dialog.login');
    return !challenge ? (
        <Container key="login" sx={{ overflowY: 'auto', height: '100%', maxWidth: 500 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Paper
                    sx={{ maxWidth: '400px', p: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                    <FormContainer>
                        <FormItem>
                            <AuthFormHeader title={email ? <I18n token="dialog.verifyAccount" /> : title} />
                        </FormItem>
                        <FormItem>
                            <FormWidget
                                name="username"
                                disabled
                                label={<I18n token="account.form.email" />}
                                value={values.username}
                                errors={valid.errors.username}
                                hasErrors={hasErrors('username')}
                                onChange={onChange}
                                onKeyUp={keyboardEventHandler}
                            />
                        </FormItem>
                        <FormItem>
                            <V>
                                <FormWidget
                                    name="password"
                                    type={showPassword ? 'text' : 'password'}
                                    autoFocus
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                                onMouseDown={(e) => e.preventDefault()}
                                                edge="end"
                                            >
                                                {showPassword ? <Icons.VisibilityOff /> : <Icons.Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label={<I18n token="account.form.password" />}
                                    value={values.password}
                                    errors={valid.errors.password}
                                    hasErrors={hasErrors('password')}
                                    onChange={onChange}
                                    onKeyUp={keyboardEventHandler}
                                />
                                {email ? (
                                    <Typography fontSize="small">
                                        <I18n token="dialog.resendText" />
                                        <Link
                                            className="clickable"
                                            onClick={() => {
                                                registerFlow(email);
                                                enqueueSnackbar('Email sent', { variant: 'success' });
                                            }}
                                        >
                                            <I18n token="code.resend" />
                                        </Link>
                                    </Typography>
                                ) : (
                                    <ForgotPassword username={values.username} />
                                )}
                            </V>
                        </FormItem>
                        <FormItem>
                            <OkCancel
                                isBusy={isBusy}
                                okLabelI18n={email ? 'dialog.continue' : 'dialog.login'}
                                cancelLabelI18n="dialog.back"
                                onOk={login}
                                onCancel={cancel}
                                isOkDisabled={!valid.isValid}
                            />
                        </FormItem>
                    </FormContainer>
                </Paper>
            </Box>
        </Container>
    ) : (
        <Challenge
            challenge={challenge}
            username={values.username}
            isBusy={isBusy}
            onCancel={cancel}
            onSubmit={submit}
        />
    );
};
