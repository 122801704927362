import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { TermInput, TermsetInfoInput } from 'sr-types/lib/metadata/v1/graphql';
import { UserContext } from '../common/auth/UserContext';
import { enqueueSnackbar } from '../common/components/Toast';
import { constants } from '../common/constants';
import { useI18n } from '../common/i18n/I18n';

import { searchClient, slimResultsQuery } from '../common/list/slimQuery';
import { useAddTerms, useTermsetSave } from '../supply/termset/termsetApi';
import {
    activateFieldDefinition as activateFieldDefMutation,
    addFieldDefinitionAssociation,
    AvailableTypeEnum,
    client,
    inactivateFieldDefinition as inactivateFieldDefMutation,
    parseFieldsToMetadata,
    removeFieldDefinitionAssociation,
    saveFieldDefinition
} from './attributes';

export const useFieldDefinitionData = (fetchFromEntity = [], status: 'active' | 'inactive' | undefined = undefined, skip = false) => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const filters = [
        {
            identifier: 'entity',
            value: 'FieldDefinition'
        }
    ];
    fetchFromEntity &&
        fetchFromEntity.map((entity) => {
            filters.push({
                identifier: 'entityType',
                value: entity
            });
        });

    status &&
        filters.push({
            identifier: 'inactive',
            value: status === 'active' ? 'false' : 'true'
        });

    const {
        data: fieldDefinitionData,
        loading: isFieldDefinitionLoading,
        refetch: fieldDefinitionRefetch
    } = useQuery(slimResultsQuery('FieldDefinition'), {
        variables: {
            filters: filters,
            page: { size: 1000, from: 0 }
        },
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        client: searchClient,
        fetchPolicy: constants.apolloFetchPolicy,
        notifyOnNetworkStatusChange: true,
        skip
    });

    return { fieldDefinitionData, isFieldDefinitionLoading, fieldDefinitionRefetch };
};

export const useFieldTypesData = () => {
    const { activeOrganizationAccount } = useContext(UserContext);

    const {
        data: fieldTypesData,
        loading: isFieldTypesDataLoading,
        refetch: fieldTypesDataRefetch
    } = useQuery(slimResultsQuery('FieldDefinition'), {
        variables: {
            filters: [
                {
                    identifier: 'entity',
                    value: 'FieldType'
                }
            ],
            page: { size: 1000, from: 0 }
        },
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        client: searchClient,
        fetchPolicy: constants.apolloFetchPolicy,
        notifyOnNetworkStatusChange: false
    });
    return { fieldTypesData, isFieldTypesDataLoading, fieldTypesDataRefetch };
};

export const useFieldDefinitionSave = () => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const { termsetSave } = useTermsetSave();
    const { saveTerms } = useAddTerms();

    const [saveFieldDef] = useMutation(saveFieldDefinition, { client: client, context: { headers: { ownerId: activeOrganizationAccount } } });
    const successMessage = useI18n('fieldDefinition.form.save.success');
    const errorMessage = useI18n('fieldDefinition.form.save.failure');

    const handlePresave = (fieldDefinition) => {
        return new Promise((resolve, reject) => {
            //presaving the termset
            if (fieldDefinition.fieldType === AvailableTypeEnum.termset && fieldDefinition.newTermset) {
                const newTermset: TermsetInfoInput = {
                    name: fieldDefinition.label.replace(/\s/g, ''),
                    label: fieldDefinition.label,
                    description: fieldDefinition.description
                };
                termsetSave(newTermset)
                    .then((response) => {
                        const { newTermset } = fieldDefinition;
                        const savedTerms: TermInput[] = newTermset.map((term) => {
                            return { text: { value: term, languageCode: 'en' } };
                        });

                        setTimeout(() => {
                            saveTerms(fieldDefinition.label.replace(/\s/g, ''), savedTerms);
                        }, 2000);

                        resolve(newTermset);
                    })
                    .catch((err) => {});
            }
            //resolve if there is nothing to presave
            resolve(undefined);
        });
    };

    const fieldDefinitionSave = (fieldDefinition, showMessage = false) => {
        if (fieldDefinition.fieldType === AvailableTypeEnum.termset && fieldDefinition.newTermset.length) {
            fieldDefinition.termset = fieldDefinition.label;
        }
        if (fieldDefinition.fieldType === AvailableTypeEnum.measurement && fieldDefinition.newTermset.length) {
            fieldDefinition.termset = fieldDefinition.label;
        }

        const savedFieldDefinition = {
            ...parseFieldsToMetadata(fieldDefinition),
            enabled: true
        };
        return new Promise((resolve, reject) => {
            handlePresave(fieldDefinition)
                .then(() => {
                    saveFieldDef({
                        variables: {
                            input: savedFieldDefinition
                        }
                    })
                        .then((saveFieldDefinitionResponse) => {
                            if (saveFieldDefinitionResponse.data.saveFieldDefinition.errors) {
                                showMessage &&
                                    enqueueSnackbar(errorMessage, {
                                        variant: 'error'
                                    });
                                reject(saveFieldDefinitionResponse);
                            } else {
                                showMessage &&
                                    enqueueSnackbar(successMessage, {
                                        variant: 'success'
                                    });
                                resolve(saveFieldDefinitionResponse);
                            }
                        })
                        .catch((err) => {
                            showMessage &&
                                enqueueSnackbar(errorMessage, {
                                    variant: 'error'
                                });
                            reject(err);
                        });
                })
                .catch((err) => {});
        });
    };
    return { fieldDefinitionSave };
};

export const useAddFieldDefinitionAssociation = () => {
    const { activeOrganizationAccount } = useContext(UserContext);

    const [addAssociation, { loading: isAddAssociationSaving }] = useMutation(addFieldDefinitionAssociation, {
        client: client,
        context: { headers: { ownerId: activeOrganizationAccount } }
    });

    const successMessage = useI18n('fieldDefinition.modal.addAssociation.single.success');
    const errorMessage = useI18n('fieldDefinition.modal.addAssociation.single.error');
    const successMessageMulti = useI18n('fieldDefinition.modal.addAssociation.multiple.success');
    const errorMessageMulti = useI18n('fieldDefinition.modal.addAssociation.multiple.error');

    const addFieldDefinitionAssociationToEntity = (fieldDefinitionId, entity, showMessage = false) => {
        return new Promise((resolve, reject) => {
            addAssociation({
                variables: {
                    id: fieldDefinitionId,
                    entity
                }
            })
                .then((addAssociationResponse) => {
                    if (!addAssociationResponse.data.errors) {
                        showMessage &&
                            enqueueSnackbar(successMessage, {
                                variant: 'success'
                            });
                        resolve(addAssociationResponse);
                    } else {
                        showMessage &&
                            enqueueSnackbar(errorMessage, {
                                variant: 'error'
                            });
                        reject(addAssociationResponse);
                    }
                })
                .catch((err) => {
                    showMessage &&
                        enqueueSnackbar(errorMessage, {
                            variant: 'error'
                        });
                    reject(err);
                });
        });
    };

    const addMultipleFieldDefAssociationToEntity = (fieldDefinitionIds, entity, showMessage = false) => {
        return new Promise((resolve, reject) => {
            const requests = fieldDefinitionIds.map((id) => {
                return addFieldDefinitionAssociationToEntity(id, entity, false);
            });
            const results = Promise.all(requests);
            results
                .then((data) => {
                    if (showMessage) {
                        enqueueSnackbar(successMessageMulti, {
                            variant: 'success'
                        });
                    }
                    resolve(data);
                })
                .catch((error) => {
                    if (showMessage) {
                        enqueueSnackbar(errorMessageMulti, {
                            variant: 'error'
                        });
                    }
                    reject(error);
                });
        });
    };

    return { addFieldDefinitionAssociationToEntity, addMultipleFieldDefAssociationToEntity, isAddAssociationSaving };
};

export const useFieldDefinitionSaveWithAssociation = () => {
    const { fieldDefinitionSave } = useFieldDefinitionSave();
    const { addFieldDefinitionAssociationToEntity } = useAddFieldDefinitionAssociation();

    const successMessage = useI18n('fieldDefinition.form.save.success');
    const errorMessage = useI18n('fieldDefinition.form.save.failure');

    const saveAndAssociateFieldDefinition = (fieldDefinition, entity, entityId, showMessage = false) => {
        return new Promise((resolve, reject) => {
            fieldDefinitionSave(fieldDefinition)
                .then((fieldDefResponse) => {
                    addFieldDefinitionAssociationToEntity((fieldDefResponse as any)?.data?.saveFieldDefinition?.id, entity, entityId)
                        .then((res) => {
                            showMessage &&
                                enqueueSnackbar(successMessage, {
                                    variant: 'success'
                                });
                            resolve(res);
                        })
                        .catch((err) => {
                            showMessage &&
                                enqueueSnackbar(errorMessage, {
                                    variant: 'error'
                                });
                            reject(err);
                        });
                })
                .catch((err) => {
                    showMessage &&
                        enqueueSnackbar(errorMessage, {
                            variant: 'error'
                        });
                    reject(err);
                });
        });
    };

    return { saveAndAssociateFieldDefinition };
};

export const useUnitsDataLazy = (unitType) => {
    const { activeOrganizationAccount } = useContext(UserContext);

    const [getUnits, { data: unitsData, loading: unitsLoading }] = useLazyQuery(slimResultsQuery('Unit'), {
        variables: {
            filters: [
                {
                    identifier: 'entity',
                    value: 'Unit'
                },
                {
                    identifier: 'measure',
                    value: unitType
                }
            ],
            page: { size: 1000, from: 0 }
        },
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        client: searchClient,
        fetchPolicy: constants.apolloFetchPolicy,
        notifyOnNetworkStatusChange: true
    });

    return { getUnits, unitsData, unitsLoading };
};

export const useUnitsData = (unitType) => {
    const { activeOrganizationAccount } = useContext(UserContext);

    const { data: unitsData, loading: unitsLoading } = useQuery(slimResultsQuery('Unit'), {
        variables: {
            filters: [
                {
                    identifier: 'entity',
                    value: 'Unit'
                },
                {
                    identifier: 'measure',
                    value: unitType
                }
            ],
            page: { size: 1000, from: 0 }
        },
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        client: searchClient,
        fetchPolicy: constants.apolloFetchPolicy,
        notifyOnNetworkStatusChange: true
    });

    return { unitsData, unitsLoading };
};

export const useInactivateFieldDefinition = () => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const [inactivateFieldDefinitionMutation] = useMutation(inactivateFieldDefMutation, { client });

    const successMessage = useI18n('fieldDefinition.inactivate.success');
    const errorMessage = useI18n('fieldDefinition.inactivate.failure');

    const inactivateFieldDefinition = (fieldDefinitionId, showMessage = false) => {
        return new Promise((resolve, reject) => {
            inactivateFieldDefinitionMutation({
                variables: {
                    id: fieldDefinitionId
                },
                context: {
                    headers: {
                        ownerId: activeOrganizationAccount
                    }
                }
            })
                .then((inactivateFieldDefinitionResponse) => {
                    if (inactivateFieldDefinitionResponse?.data?.inactivateFieldDefinition?.id) {
                        showMessage &&
                            enqueueSnackbar(successMessage, {
                                variant: 'success'
                            });
                        resolve(inactivateFieldDefinitionResponse);
                    } else {
                        showMessage &&
                            enqueueSnackbar(errorMessage, {
                                variant: 'error'
                            });
                        reject(inactivateFieldDefinitionResponse);
                    }
                })
                .catch((err) => {
                    showMessage &&
                        enqueueSnackbar(errorMessage, {
                            variant: 'error'
                        });
                    reject(err);
                });
        });
    };
    return { inactivateFieldDefinition };
};

export const useActivateFieldDefinition = () => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const [activateFieldDefinitionMutation] = useMutation(activateFieldDefMutation, { client });

    const successMessage = useI18n('fieldDefinition.activate.success');
    const errorMessage = useI18n('fieldDefinition.activate.failure');

    const activateFieldDefinition = (fieldDefinitionId, showMessage = false) => {
        return new Promise((resolve, reject) => {
            activateFieldDefinitionMutation({
                variables: {
                    id: fieldDefinitionId
                },
                context: {
                    headers: {
                        ownerId: activeOrganizationAccount
                    }
                }
            })
                .then((activateFieldDefinitionResponse) => {
                    if (activateFieldDefinitionResponse?.data?.activateFieldDefinition?.id) {
                        showMessage &&
                            enqueueSnackbar(successMessage, {
                                variant: 'success'
                            });
                        resolve(activateFieldDefinitionResponse);
                    } else {
                        showMessage &&
                            enqueueSnackbar(errorMessage, {
                                variant: 'error'
                            });
                        reject(activateFieldDefinitionResponse);
                    }
                })
                .catch((err) => {
                    showMessage &&
                        enqueueSnackbar(errorMessage, {
                            variant: 'error'
                        });
                    reject(err);
                });
        });
    };
    return { activateFieldDefinition };
};

export const useRemoveFieldDefinitionAssociation = () => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const [removeFieldDefinition] = useMutation(removeFieldDefinitionAssociation, { client });

    const successMessage = useI18n('fieldDefinition.attribute.delete.success');
    const errorMessage = useI18n('fieldDefinition.attribute.delete.error');

    const deleteFieldDefinitionAssociation = ({ id, entity, entityId, onSuccess = undefined, onError = undefined }) => {
        return new Promise((resolve, reject) => {
            removeFieldDefinition({
                variables: {
                    id,
                    entity,
                    entityId
                },
                context: {
                    headers: {
                        ownerId: activeOrganizationAccount
                    }
                }
            })
                .then((removeFieldDefinitionAssociation) => {
                    if (removeFieldDefinitionAssociation?.data?.removeFieldDefinitionAssociation?.errors) {
                        enqueueSnackbar(errorMessage, { variant: 'error' });
                        onError?.();
                        reject(removeFieldDefinitionAssociation);
                    } else {
                        enqueueSnackbar(successMessage, { variant: 'success' });
                        onSuccess?.();
                        resolve(removeFieldDefinitionAssociation);
                    }
                })
                .catch((err) => {
                    enqueueSnackbar(errorMessage, {
                        variant: 'error'
                    });
                    reject(err);
                });
        });
    };
    return { deleteFieldDefinitionAssociation };
};
