import { cloneDeep } from 'lodash';
import { Contact, ContactInput, NameInput } from 'sr-types/lib/production/v1/graphql';

export const getKeyPeopleInput = (
    peopleList: ContactWithKey[],
    markedPerson?: ContactWithKey,
    unmarkedPerson?: ContactWithKey,
    markNewPerson?: ContactWithKey
): ContactInput[] => {
    const people = cloneDeep(peopleList);
    if (markedPerson) {
        people[
            peopleList.findIndex((person) => person?.personReference?.id === markedPerson?.personReference?.id)
        ].isKey = true;
    } else if (unmarkedPerson) {
        people[
            peopleList.findIndex((person) => person?.personReference?.id === unmarkedPerson?.personReference?.id)
        ].isKey = false;
    } else if (markNewPerson) {
        people.push(markNewPerson);
    }
    return people
        .filter((person) => person.isKey)
        .map((person) => {
            const { personReference, name, role } = person;

            return {
                personReference: personReference,
                name: { firstName: name.firstName, lastName: name.lastName },
                role
            };
        });
};

export type ContactWithKey = Contact & { isKey: boolean };

export const getPeopleWithKeyFlag = (peopleList: Contact[], keyPeople: Contact[]): ContactWithKey[] => {
    return peopleList
        ? peopleList.map((person) => {
              return {
                  ...person,
                  isKey:
                      Array.isArray(keyPeople) &&
                      keyPeople.some((contact) => contact?.personReference?.id === person?.personReference?.id)
              };
          })
        : [];
};

export const extractNames = (query: string): NameInput => {
    const [firstName, ...lastName] = query.toString().split(' ');

    const prePopulateName: NameInput = {
        firstName: firstName || '',
        lastName: lastName.length > 0 ? lastName.join(' ') : ''
    };

    return prePopulateName;
};
