import { GridColDef, GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import React from 'react';
import PersonListTile from '../../common/components/person/PersonListTile';
import LookupGridField from '../../common/list/LookupGridField';
import {
    contactToOption,
    contactToUpdate,
    defaultEntityToOption,
    defaultFieldUpdater,
    defaultOptionRenderer,
    organizationToOption,
    organizationToUpdate,
    productionToOption,
    productionToUpdate,
    valueGetter
} from '../opportunity/details/ContactListUtils';
import { EMPTY_STATE as EMPTY_STATE_ORGANIZATION } from '../../../src/organization/organization';
import MuiPhoneNumber from 'material-ui-phone-number-2';
import { AsYouType } from 'libphonenumber-js';

export const getColumns = (
    colDefs,
    setIsRowUpdated,
    afterSearch,
    getNonFuzzyQueryParamsForEmail,
    mutationObject,
    autoCreate
) => {
    const saveOrganization = mutationObject['organization'];
    const saveProduction = mutationObject['production'];
    const createTerm = mutationObject['createTerm'];

    const PhoneNumberCell = (props: GridRenderEditCellParams) => {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();
        const asYouType = new AsYouType();

        const handleChange = (newValue: number | null) => {
            setIsRowUpdated && setIsRowUpdated(true);
            asYouType.input((newValue || value) as string);
            apiRef.current.setEditCellValue({
                id,
                field,
                value: asYouType.getNumber() ? asYouType.getNumber().number : asYouType.isValid() ? newValue : null
            });
        };

        return (
            <MuiPhoneNumber
                id={'Contact phone number'}
                defaultCountry={'us'}
                disableAreaCodes
                countryCodeEditable={false}
                autoFormat
                value={value}
                onChange={(value) => handleChange(value)}
                InputProps={{
                    size: 'small'
                }}
            />
        );
    };

    const renderPhoneNumberEditInputCell: GridColDef['renderCell'] = (params) => {
        return <PhoneNumberCell {...params} setIsRowUpdated={setIsRowUpdated} />;
    };

    const onAutoCreateOrganization = (inputValue) => {
        return new Promise((resolve, reject) => {
            saveOrganization({
                variables: {
                    input: {
                        ...EMPTY_STATE_ORGANIZATION,
                        publicName: inputValue,
                        websiteURL: ''
                    }
                }
            })
                .then((res) => {
                    const { autoCreateOrganization } = res.data;
                    const updatedCreateOption = {
                        name: inputValue,
                        reference: autoCreateOrganization.reference
                    };
                    resolve(updatedCreateOption);
                })
                .catch((err) => {
                    console.error('Failed to create term:', err);
                    reject(err);
                });
        });
    };

    const onAutoCreateProduction = (inputValue) => {
        return new Promise((resolve, reject) => {
            saveProduction({
                variables: {
                    input: {
                        publicTitle: inputValue
                    }
                }
            })
                .then((res) => {
                    const { autoCreateProduction } = res.data;
                    const updatedCreateOption = {
                        name: inputValue,
                        reference: autoCreateProduction.reference
                    };
                    resolve(updatedCreateOption);
                })
                .catch((err) => {
                    console.error('Failed to create term:', err);
                    reject(err);
                });
        });
    };

    const onAutoCreateTerm = (inputValue, entity) => {
        return new Promise((resolve, reject) => {
            createTerm({
                variables: {
                    termset: entity,
                    input: {
                        text: {
                            value: inputValue,
                            languageCode: 'en'
                        }
                    }
                }
            })
                .then((res) => {
                    console.log('Created term:', res);
                    const { id } = res.data.autoCreateTermset;
                    const updatedCreateOption = {
                        name: inputValue,
                        reference: {
                            id: id,
                            deleted: false,
                            eventId: '',
                            type: 'Termset',
                            uriPrefix: 'termsets'
                        }
                    };
                    resolve(updatedCreateOption);
                })
                .catch((err) => {
                    console.error('Failed to create term:', err);
                    reject(err);
                });
        });
    };

    const firstName: GridColDef = {
        field: 'name.firstName',
        headerName: 'First name',
        flex: 1,
        sortable: true,
        editable: true,
        minWidth: 120,
        valueGetter: valueGetter,
        renderEditCell: (params: GridRenderEditCellParams) => {
            return (
                <LookupGridField
                    {...params}
                    setIsRowUpdated={setIsRowUpdated}
                    columns={colDefs}
                    filters={[{ identifier: 'entity', value: 'Person' }]}
                    OptionRenderer={PersonListTile}
                    toOption={contactToOption}
                    entityToUpdate={contactToUpdate}
                    filter={{ expressions: [{ field: 'public', value: { values: ['false'] } }] }}
                />
            );
        }
    };

    const lastName: GridColDef = {
        field: 'name.lastName',
        headerName: 'Last name',
        flex: 1,
        sortable: true,
        editable: true,
        minWidth: 120,
        valueGetter: valueGetter,
        renderEditCell: (params: GridRenderEditCellParams) => {
            return (
                <LookupGridField
                    {...params}
                    setIsRowUpdated={setIsRowUpdated}
                    columns={colDefs}
                    filters={[{ identifier: 'entity', value: 'Person' }]}
                    OptionRenderer={PersonListTile}
                    toOption={contactToOption}
                    entityToUpdate={contactToUpdate}
                    filter={{ expressions: [{ field: 'public', value: { values: ['false'] } }] }}
                />
            );
        }
    };
    const organization: GridColDef = {
        field: 'organization',
        headerName: 'Organization',
        flex: 1,
        sortable: true,
        editable: true,
        type: 'string',
        minWidth: 80,
        valueGetter: valueGetter,
        renderEditCell: (params: GridRenderEditCellParams) => {
            return (
                <LookupGridField
                    {...params}
                    setIsRowUpdated={setIsRowUpdated}
                    columns={colDefs}
                    filters={[{ identifier: 'entity', value: 'Organization' }]}
                    OptionRenderer={defaultOptionRenderer('organization')}
                    toOption={organizationToOption}
                    entityToUpdate={organizationToUpdate}
                    onAutoCreate={onAutoCreateOrganization}
                    autoCreate={autoCreate}
                    filter={{ expressions: [{ field: 'inactive', value: { values: ['false'] } }] }}
                />
            );
        }
    };
    const email: GridColDef = {
        field: 'contactInfo.email.address',
        headerName: 'Email',
        flex: 0.75,
        sortable: true,
        editable: true,
        minWidth: 80,
        valueGetter: valueGetter,
        renderEditCell: (params: GridRenderEditCellParams) => {
            return (
                <LookupGridField
                    {...params}
                    setIsRowUpdated={setIsRowUpdated}
                    columns={colDefs}
                    filters={[{ identifier: 'entity', value: 'Person' }]}
                    OptionRenderer={PersonListTile}
                    toOption={contactToOption}
                    entityToUpdate={contactToUpdate}
                    afterSearch={afterSearch}
                    getNonFuzzyQueryParams={getNonFuzzyQueryParamsForEmail}
                    filter={{ expressions: [{ field: 'public', value: { values: ['false'] } }] }}
                />
            );
        }
    };

    const phone: GridColDef = {
        field: 'contactInfo.phone.number',
        headerName: 'Phone',
        flex: 0.75,
        sortable: true,
        editable: true,
        minWidth: 80,
        valueGetter: valueGetter,
        renderEditCell: renderPhoneNumberEditInputCell
    };

    const title: GridColDef = {
        field: 'title',
        headerName: 'Title',
        flex: 0.75,
        sortable: true,
        editable: true,
        minWidth: 80,
        valueGetter: valueGetter,
        renderEditCell: (params: GridRenderEditCellParams) => {
            return (
                <LookupGridField
                    {...params}
                    setIsRowUpdated={setIsRowUpdated}
                    columns={colDefs}
                    filters={[{ identifier: 'entity', value: 'Title' }]}
                    OptionRenderer={defaultOptionRenderer('name')}
                    toOption={defaultEntityToOption}
                    entityToUpdate={defaultFieldUpdater('name')}
                    onAutoCreate={onAutoCreateTerm}
                    autoCreate={autoCreate}
                />
            );
        }
    };
    const department: GridColDef = {
        field: 'organizationDepartment',
        headerName: 'Department',
        flex: 0.75,
        sortable: true,
        editable: true,
        minWidth: 80,
        valueGetter: valueGetter,
        renderEditCell: (params: GridRenderEditCellParams) => {
            return (
                <LookupGridField
                    {...params}
                    setIsRowUpdated={setIsRowUpdated}
                    columns={colDefs}
                    filters={[{ identifier: 'entity', value: 'Department' }]}
                    OptionRenderer={defaultOptionRenderer('name')}
                    toOption={defaultEntityToOption}
                    entityToUpdate={defaultFieldUpdater('name')}
                    onAutoCreate={onAutoCreateTerm}
                    autoCreate={autoCreate}
                />
            );
        }
    };
    const role: GridColDef = {
        field: 'role',
        headerName: 'Role',
        flex: 0.75,
        sortable: true,
        editable: true,
        minWidth: 80,
        valueGetter: valueGetter,
        renderEditCell: (params: GridRenderEditCellParams) => {
            return (
                <LookupGridField
                    {...params}
                    setIsRowUpdated={setIsRowUpdated}
                    columns={colDefs}
                    filters={[{ identifier: 'entity', value: 'Role' }]}
                    OptionRenderer={defaultOptionRenderer('name')}
                    toOption={defaultEntityToOption}
                    entityToUpdate={defaultFieldUpdater('name')}
                    onAutoCreate={onAutoCreateTerm}
                    autoCreate={autoCreate}
                />
            );
        }
    };
    const production: GridColDef = {
        field: 'production',
        headerName: 'Production',
        flex: 1,
        sortable: true,
        editable: true,
        type: 'string',
        minWidth: 80,
        valueGetter: valueGetter,
        renderEditCell: (params: GridRenderEditCellParams) => {
            return (
                <LookupGridField
                    {...params}
                    setIsRowUpdated={setIsRowUpdated}
                    columns={colDefs}
                    filters={[{ identifier: 'entity', value: 'Production' }]}
                    OptionRenderer={defaultOptionRenderer('production')}
                    toOption={productionToOption}
                    entityToUpdate={productionToUpdate}
                    onAutoCreate={onAutoCreateProduction}
                    autoCreate={autoCreate}
                />
            );
        }
    };
    const productionRole: GridColDef = {
        field: 'productionRole',
        headerName: 'Production Role',
        flex: 0.75,
        sortable: true,
        editable: true,
        type: 'string',
        minWidth: 80,
        valueGetter: valueGetter,
        renderEditCell: (params: GridRenderEditCellParams) => {
            return (
                <LookupGridField
                    {...params}
                    setIsRowUpdated={setIsRowUpdated}
                    columns={colDefs}
                    filters={[{ identifier: 'entity', value: 'Role' }]}
                    OptionRenderer={defaultOptionRenderer('name')}
                    toOption={defaultEntityToOption}
                    entityToUpdate={defaultFieldUpdater('name')}
                    onAutoCreate={onAutoCreateTerm}
                    autoCreate={autoCreate}
                />
            );
        }
    };

    const productionDepartment: GridColDef = {
        field: 'productionDepartment',
        headerName: 'Production Department',
        flex: 0.75,
        sortable: true,
        editable: true,
        minWidth: 80,
        valueGetter: valueGetter,
        renderEditCell: (params: GridRenderEditCellParams) => {
            return (
                <LookupGridField
                    {...params}
                    setIsRowUpdated={setIsRowUpdated}
                    columns={colDefs}
                    filters={[{ identifier: 'entity', value: 'Department' }]}
                    OptionRenderer={defaultOptionRenderer('name')}
                    toOption={defaultEntityToOption}
                    entityToUpdate={defaultFieldUpdater('name')}
                    onAutoCreate={onAutoCreateTerm}
                    autoCreate={autoCreate}
                />
            );
        }
    };

    const fullName: GridColDef = {
        field: 'fullName',
        headerName: 'Full name',
        flex: 1,
        sortable: true
    };

    const personReference: GridColDef = {
        field: 'personReference',
        headerName: 'Person Reference',
        flex: 1,
        sortable: true,
        editable: true,
        hideable: false,
        valueGetter: valueGetter
    };

    const productionReference: GridColDef = {
        field: 'productionReference',
        headerName: 'Production Reference',
        flex: 1,
        sortable: true,
        editable: true,
        hideable: false,
        valueGetter: valueGetter
    };

    const organizationReference: GridColDef = {
        field: 'organizationReference',
        headerName: 'Organization Reference',
        flex: 1,
        sortable: true,
        editable: true,
        hideable: false,
        valueGetter: valueGetter
    };

    const columns = [
        firstName,
        lastName,
        organization,
        email,
        phone,
        title,
        department,
        role,
        production,
        productionRole,
        productionDepartment,
        personReference,
        productionReference,
        organizationReference
    ];

    let cols = [...columns];
    const configureHiddenOrImmutableColumns = () => {
        const immutableColumns = colDefs.filter((col) => !col.editable).map((col) => col.field);
        const hiddenColumns = colDefs.filter((col) => col.hide).map((col) => col.field);

        immutableColumns.forEach((fieldName) => {
            const col = cols.find((col) => col.field === fieldName);
            if (col) {
                col.editable = false;
            }
        });

        hiddenColumns.forEach((fieldName) => {
            const col = cols.find((col) => col.field === fieldName);
            if (col) {
                col.hide = true;
            }
        });

        return cols;
    };

    const updatedColumns = configureHiddenOrImmutableColumns();

    return updatedColumns;
};
