import { Alert, Box, Popover, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import hljs from 'highlight.js/lib/core';
import json from 'highlight.js/lib/languages/json';
import React, { Fragment, ReactElement, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { KeyValueMap } from './KeyValueMap';
import { useStyling } from './Theme';
import { BreakpointSize } from './hooks/breakpoints';
import { useCurrentBreakpoint } from './hooks/useCurrentBreakpoint';
import useWidth from './components/layout/useWidth';
import { FormContext } from './form/FormContext';
import { isDebugValue } from './utils/DevelAtoms';

hljs.registerLanguage('json', json);

type BaseLayoutProps = {
    id?: string;
    isVertical?: boolean;
    fill?: boolean;
    grow?: boolean;
    hide?: boolean;
    sx?: SxProps;
    layouts?: any[];
    className?: string;
    passthroughProps?: KeyValueMap<string, any>;
    responsive?: boolean;
    tileWidth?: number;
    gap?: number;
    children: typeof React.Children;
};

const BaseLayout = ({
    id = undefined,
    isVertical = false,
    fill = false,
    grow = false,
    hide = false,
    sx = {} as SxProps,
    layouts = undefined,
    className = undefined,
    passthroughProps = undefined,
    responsive = false,
    tileWidth = undefined,
    gap = 0,
    customMoreElement = undefined,
    children,
    ...rest
}) => {
    const containerSx: SxProps = {
        width: '100%',
        height: fill ? '100%' : 'unset',
        display: 'flex',
        gap: gap,
        alignItems: isVertical ? 'flex-start' : 'center',
        alignContent: 'center',
        flexDirection: isVertical ? 'column' : 'row',
        flexGrow: grow ? 2 : 0,
        flexWrap: 'nowrap',
        ...sx
    };
    const klass = (isVertical ? '_v' : '_h') + (className ? ` ${className}` : '');
    const targetRef = useRef<HTMLElement>();
    const containerWidth = useWidth(targetRef);
    const bp = useCurrentBreakpoint();
    const [fit, setFit] = useState<ReactElement[]>();
    const [overflow, setOverflow] = useState<ReactElement[]>();
    const [more, setMore] = useState<ReactElement>();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    if (responsive && !tileWidth) {
        throw new Error('tileWidth must be set if responsive is true');
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOverflow = useCallback((content: ReactElement[], cw: number) => {
        const canFit = Math.floor(containerWidth / cw) - 1; // - 1 is for 'more' label to fit.
        setFit(content.slice(0, canFit));
        setOverflow(content.slice(canFit, content.length));
        setMore(
            <V sx={{ width: tileWidth }}>
                <Typography
                    className="clickable"
                    onClick={handleClick}
                    sx={{ fontSize: 'small', whiteSpace: 'nowrap' }}
                >{`+${content.length - canFit} more`}</Typography>
                {customMoreElement || <></>}
            </V>
        );
    }, [containerWidth, customMoreElement, tileWidth]);

    useEffect(() => {
        let content: ReactElement[];
        if (layouts && bp && layouts[bp]) {
            content = layouts[bp];
        } else if (typeof children === 'function') {
            content = children();
        } else {
            content = children;
        }
        if (responsive) {
            if (containerWidth) {
                const cw = tileWidth + 8 * gap;
                if ((content.length + 1) * cw <= containerWidth && customMoreElement) {
                    // Everything can fit with customMoreElement
                    setFit(content);
                    setOverflow(undefined);
                    setMore(customMoreElement);
                    handleClose();
                } else if (customMoreElement) {
                    handleOverflow(content, cw);
                } else if (content.length * cw <= containerWidth) {
                    // Everything can fit
                    setFit(content);
                    setOverflow(undefined);
                    setMore(undefined);
                    handleClose();
                } else {
                    handleOverflow(content, cw);
                }
            }
        } else {
            setFit(content);
            setOverflow(undefined);
            setMore(undefined);
        }
    }, [children, tileWidth, responsive, layouts, bp, containerWidth, gap, customMoreElement]);

    return hide ? (
        <React.Fragment />
    ) : (
        <>
            <Box ref={targetRef} className={klass} sx={containerSx} {...rest}>
                {fit}
                {more}
            </Box>
            {responsive && more ? (
                <Popover
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <V sx={{ width: tileWidth, m: 1, gap: 1 }}>{overflow}</V>
                </Popover>
            ) : (
                <Fragment />
            )}
        </>
    );
};

export const V = ({ children, ...rest }) => {
    return (
        <BaseLayout isVertical={true} {...rest}>
            {children}
        </BaseLayout>
    );
};

export const H = ({ children, ...rest }) => {
    return (
        <BaseLayout isVertical={false} {...rest}>
            {children}
        </BaseLayout>
    );
};

export const C = ({ children = undefined, grow = false, hide = false, className = undefined, ...rest }) => {
    const containerCss = {
        flexGrow: grow ? 2 : 0
        // overflow: 'hidden'
    };
    return hide ? (
        <React.Fragment />
    ) : (
        <Box sx={containerCss} {...rest} className={`_c${className ? ' ' + className : ''}`}>
            {children}
        </Box>
    );
};
export const Conditional = ({ condition, children }) => {
    if (condition) {
        return typeof children === 'function' ? children() : children;
    } else {
        return <React.Fragment />;
    }
};

export type Layouts = {
    [key in BreakpointSize]?: React.ReactElement;
};

const StatePreview = ({ preview = undefined }) => {
    const editorRef = useRef(null);
    const { validation } = useContext(FormContext);
    const { isDarkMode } = useStyling();

    useEffect(() => {
        if (editorRef && editorRef.current) {
            editorRef.current.getAction('editor.action.formatDocument').run();
        }
    }, [preview]);
    const highlightedCode = hljs.highlight(JSON.stringify(preview, null, 2), { language: 'json' }).value;
    return (
        <V sx={{ height: '100%', p: 1 }}>
            <H sx={{ width: '100%' }}>
                <Alert sx={{ width: '100%' }} severity={'info'} variant={'standard'}>
                    State preview
                </Alert>
            </H>
            <pre style={{ overflow: 'auto', width: '100%', margin: 0 }}>
                <code className="language-json" dangerouslySetInnerHTML={{ __html: highlightedCode }}></code>
            </pre>
        </V>
    );
};

type ScrollableLayoutProps = {
    preview?: any;
    sx?: SxProps;
    height?: string;
    horizontal?: boolean;
    layouts?: Layouts;
    children?: React.ReactNode;
};

export const ScrollableLayout = (props: ScrollableLayoutProps) => {
    const {
        preview = undefined,
        sx = {},
        height = '100%',
        horizontal = false,
        children = undefined,
        layouts = undefined
    } = props;
    const isDebug = useRecoilValue(isDebugValue);
    const outer = { overflow: 'hidden', height: '100%', flexGrow: 2 };
    const inner = { overflowX: 'hidden', overflowY: 'auto', height: '100%', gap: 1, p: 1, flexGrow: 2, ...sx };
    const Container = horizontal ? H : V;

    return (
        <>
            <Container
                className={'scrollable-layout-outer'}
                sx={{ ...outer, flexGrow: 2, height: height, alignItems: 'flex-start' }}
            >
                <Container className={'scrollable-layout-inner'} sx={inner} layouts={layouts}>
                    {children}
                </Container>
            </Container>
            {isDebug && preview ? (
                <V sx={{ ...outer, width: '400px' }}>
                    <StatePreview preview={preview} />
                </V>
            ) : (
                <React.Fragment />
            )}
        </>
    );
};
