import { Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { Activity, KeyValue } from 'sr-types/lib/production/v1/graphql';
import EllipsisText from '../../common/components/EllipsisText';
import { enqueueSnackbar } from '../../common/components/Toast';
import I18n, { useI18n } from '../../common/i18n/I18n';
import { H, V } from '../../common/Layout';
import { useStyling } from '../../common/Theme';
import { getStringFromDateRange } from '../helpers/activityUtils';
import AddMore from './AddMore';
import NoContent from './NoContent';
import { LabelValueDisplay } from '../../attributes/AttributeValue';
import NoValueAvailable from './NoValueAvailable';

type KeyDatesProps = {
    activities: Activity[];
    onAdd?: React.MouseEventHandler<HTMLButtonElement>;
    onEdit: (id: string) => void;
    isSaving?: boolean;
    isReadOnly?: boolean;
};

const KeyValuePreview = ({ name, value, addDate }) => {
    const { theme } = useStyling();
    return (
        name && (
            <V
                sx={{
                    width: '220px',
                    alignItems: 'flex-start',
                    gap: 0.5
                }}
            >
                <>
                    {value ? (
                        <LabelValueDisplay label={name} value={value} />
                    ) : (
                        <>
                            <EllipsisText text={name} sx={{ fontSize: 'small', color: theme.palette.text.disabled }} />
                            <Typography
                                variant="subtitle1"
                                noWrap={true}
                                sx={{
                                    color: theme.palette.primary.main,
                                    '&:hover': {
                                        cursor: 'pointer'
                                    }
                                }}
                                onClick={addDate}
                            >
                                <I18n token="form.activity.addDate" />
                            </Typography>
                        </>
                    )}
                </>
            </V>
        )
    );
};

export default ({ activities, onAdd, onEdit, isSaving = false, isReadOnly = false }: KeyDatesProps) => {
    const { theme } = useStyling();
    return Array.isArray(activities) && activities.length ? (
        <H
            gap={1}
            sx={{ alignItems: 'center', flexWrap: 'wrap' }}
            responsive
            tileWidth={220}
            customMoreElement={
                !isReadOnly && <AddMore i18nToken="production.header.activities.addKeyDates" onAdd={onAdd} disabled={isSaving} />
            }
        >
            {activities.map((activity: Activity, index) => {
                return (
                    <KeyValuePreview
                        key={index}
                        name={activity?.name}
                        value={`${getStringFromDateRange(activity?.dateRange)}`}
                        addDate={() => {
                            onEdit(activity?.identity?.id);
                        }}
                    />
                );
            })}
        </H>
    ) : isReadOnly ? (
        <NoValueAvailable text={useI18n('production.header.keyActivity.notAvailable')} />
    ) : (
        <NoContent
            containerSx={{
                g: 1,
                justifyContent: 'center',
                width: '220px',
                height: '43px',
                color: theme.palette.primary.main,
                '&:hover': {
                    cursor: 'pointer'
                }
            }}
            i18nToken="production.header.activities.addKeyDates"
            onAdd={onAdd}
            disabled={isSaving}
        />
    );
};
