import { ApolloProvider, useMutation } from '@apollo/client';
import { SearchkitClient, SearchkitProvider } from '@searchkit/client';
import { query } from 'express';
import _ from 'lodash';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { PersonInput } from 'sr-types/lib/person/v1/graphql';
import {
    Contact,
    ContactInfo,
    ContactInput,
    ManageContactInput,
    NameInput,
    Reference
} from 'sr-types/lib/production/v1/graphql';
import { ContactInfoInput } from 'sr-types/lib/search/v1/graphql';
import { UserContext } from '../common/auth/UserContext';
import { constants } from '../common/constants';
import FormModal from '../common/form/FormModal';
import I18n, { useI18n } from '../common/i18n/I18n';
import { V } from '../common/Layout';
import DataList from '../common/list/DataList';
import ListView from '../common/list/ListView';
import { searchClient, slimResultsQuery } from '../common/list/slimQuery';
import GuestEditor from '../person/GuestEditor';
import usePersonPreFilters from '../person/usePersonPreFilters';
import SearchPanel from '../supply/searchkit/facets/SearchPanel';
import { ContactWithKey, extractNames, getKeyPeopleInput, getPeopleWithKeyFlag } from './helpers/peopleUtils';
import { addPersonToProduction, client } from './helpers/production';
import { useSaveKeyPeople } from './helpers/productionApi';
import KeyPeopleActions from './KeyPeopleActions';
import KeyPeopleTile from './KeyPeopleTile';

interface PersonWithDepartment extends ContactWithKey {
    department: string;
}

const toContactInput = (input: PersonInput, id: string): ContactInput => {
    const { contactInfo, name, professionalRoles, creativeCredits, experiences } = input;

    const associatedPersonInput: ContactInput = {
        contactInfo: (contactInfo as ContactInfoInput) || undefined,
        name,
        personReference: {
            id: id,
            label: `${name.firstName} ${name.lastName}`,
            type: 'Person'
        },
        role: professionalRoles?.[0] || undefined
    };

    if (creativeCredits?.[0]) {
        const cc = creativeCredits[0];
        const { showReference, showName, department, professionalRoles } = cc;
        Object.assign(associatedPersonInput, {
            productionReference: showReference || undefined,
            production: showName || undefined,
            productionDepartment: department || undefined,
            productionRole: professionalRoles?.[0] || undefined
        });
    }

    if (experiences?.[0]) {
        const exp = experiences[0];
        const { experienceName, department, organizationReference, title } = exp;
        Object.assign(associatedPersonInput, {
            organization: experienceName || undefined,
            organizationDepartment: department || undefined,
            organizationReference: organizationReference || undefined,
            title: title || undefined
        });
    }

    Object.keys(associatedPersonInput).forEach(
        (key) => associatedPersonInput[key] === undefined && delete associatedPersonInput[key]
    );

    return associatedPersonInput;
};

const List = ({
    id,
    loading,
    data,
    entity,
    feature,
    productionId,
    setIsCreate,
    peopleWithKey,
    setSearchAllPeople,
    setQuery,
    ...props
}) => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const [isButtonLoading, setIsButtonLoading] = useState({ id: '', isSaving: false });
    const { saveKeyPeople } = useSaveKeyPeople();
    const [savePersonToProduction] = useMutation(addPersonToProduction, { client });

    const filteredData = useMemo(() => {
        const associatedPeopleIds = peopleWithKey.map((p) => {
            return p?.personReference?.id;
        });

        if (data?.results?.hits?.items) {
            return data.results.hits.items
                .filter((person) => !associatedPeopleIds.includes(person?.reference?.id))
                .map((p) => {
                    const { reference, name, ...otherFields } = p;
                    return { ...otherFields, personReference: reference, name: extractNames(name) };
                });
        }
        return [];
    }, [data]);

    return (
        <DataList
            {...props}
            id={id}
            loading={loading}
            feature={feature}
            pagination={data?.results?.hits?.page}
            items={filteredData}
            ItemRenderer={(props) => {
                return (
                    <KeyPeopleTile
                        {...props}
                        isLoading={isButtonLoading}
                        onMarkClick={(person) => {
                            setIsButtonLoading({ id: person?.personReference?.id, isSaving: true });
                            const variables: { productionId: string; input: ManageContactInput } = {
                                productionId: productionId,
                                input: {
                                    new: {
                                        contactInfo: person.contactInfo as ContactInfoInput,
                                        personReference: person.personReference,
                                        name: person.name as NameInput,
                                        productionReference: person.productionReference,
                                        production: person.production,
                                        organization: person.organization,
                                        title: person.title,
                                        organizationDepartment: (person as PersonWithDepartment).department,
                                        role: person.role,
                                        productionRole: person.productionRole,
                                        productionDepartment: person.productionDepartment
                                    }
                                }
                            };
                            savePersonToProduction({
                                variables: variables,
                                context: {
                                    headers: {
                                        ownerId: activeOrganizationAccount
                                    }
                                }
                            })
                                .then(() => {
                                    saveKeyPeople(
                                        productionId,
                                        getKeyPeopleInput(peopleWithKey, undefined, undefined, {
                                            ...variables.input.new,
                                            isKey: true
                                        }),
                                        true
                                    )
                                        .then(() => {
                                            //set timeout is required until issue with eventual consistency is fixed on service
                                            setTimeout(() => {
                                                client.refetchQueries({ include: ['Production'] }).finally(() => {
                                                    setIsButtonLoading({ id: '', isSaving: false });
                                                });
                                            }, 300);
                                            setSearchAllPeople(false);
                                            setQuery('');
                                        })
                                        .catch(() => {
                                            setIsButtonLoading({ id: '', isSaving: false });
                                        });
                                    setSearchAllPeople(false);
                                    setQuery('');
                                })
                                .catch(() => {
                                    setIsButtonLoading({ id: '', isSaving: false });
                                });
                        }}
                    />
                );
            }}
            disableRipple={true}
            onClick={(item) => {}}
        />
    );
};

export default ({
    open,
    onClose,
    peopleData,
    keyPeople,
    id = undefined,
    productionId = undefined,
    productionRef = undefined
}: {
    open?: boolean;
    onClose: () => void;
    peopleData: Contact[];
    keyPeople: Contact[];
    id?: string;
    productionId?: string;
    productionRef?: Reference;
}) => {
    const [isCreate, setIsCreate] = useState(false);
    const [searchAllPeople, setSearchAllPeople] = useState(false);
    const [isPersonSaving, setIsPersonSaving] = useState({ id: '', isSaving: false });
    const { saveKeyPeople } = useSaveKeyPeople();
    const [isFormSaving, setIsFormSaving] = useState(false);
    const [query, setQuery] = useState('');
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);
    const [savePersonToProduction] = useMutation(addPersonToProduction, { client });
    const guestEditorRef = useRef(null);
    const { activeOrganizationAccount } = useContext(UserContext);
    const preFilters = usePersonPreFilters();
    const searchkit = new SearchkitClient();

    const peopleWithKey: ContactWithKey[] = useMemo(() => {
        return getPeopleWithKeyFlag(peopleData, keyPeople);
    }, [peopleData, keyPeople]);

    const filteredPeopleList: ContactWithKey[] = useMemo(() => {
        const people = getPeopleWithKeyFlag(peopleData, keyPeople);

        if (query) {
            const searchWords = query.toLowerCase().split(' ');
            return people.filter((person) =>
                searchWords.every(
                    (word) =>
                        person.contactInfo?.email?.address.toLowerCase().includes(word) ||
                        person.name.firstName.toLowerCase().includes(word) ||
                        person.name.lastName.toLowerCase().includes(word) ||
                        person.role?.toLowerCase().includes(word)
                )
            );
        }

        return people;
    }, [peopleData, keyPeople, query]);

    useEffect(() => {
        setSearchAllPeople(false);
    }, [query]);

    useEffect(() => {
        if (Array.isArray(filteredPeopleList) && filteredPeopleList.length === 0) {
            setSearchAllPeople(true);
        }
    }, [filteredPeopleList]);

    return (
        <FormModal
            width={'580px'}
            isOpen={open}
            onClose={() => {
                setIsCreate(false);
                setSearchAllPeople(false);
                onClose();
            }}
            isSaving={isFormSaving}
            isSaveDisabled={isSaveDisabled}
            title={<I18n token="form.people.addKeyPeople" />}
            id={`keyDates`}
            loadingButtonVariant="contained"
            hideButtons={!isCreate}
            onSave={() => {
                setIsFormSaving(true);
                guestEditorRef.current.handleSave(
                    (input, id) => {
                        const associatedPersonInput = toContactInput(input, id);

                        const variables: { productionId: string; input: ManageContactInput } = {
                            productionId: productionId,
                            input: {
                                new: {
                                    ...associatedPersonInput
                                }
                            }
                        };
                        savePersonToProduction({
                            variables: variables,
                            context: {
                                headers: {
                                    ownerId: activeOrganizationAccount
                                }
                            }
                        })
                            .then(() => {
                                saveKeyPeople(
                                    productionId,
                                    getKeyPeopleInput(peopleWithKey, undefined, undefined, {
                                        ...variables.input.new,
                                        isKey: true
                                    }),
                                    true
                                )
                                    .then(() => {
                                        //set timeout is required until issue with eventual consistency is fixed on service
                                        setTimeout(() => {
                                            client.refetchQueries({ include: ['Production'] }).finally(() => {});
                                        }, 300);
                                        setQuery('');
                                        setIsCreate(false);
                                        setSearchAllPeople(false);
                                        setIsFormSaving(false);
                                    })
                                    .catch(() => {
                                        setIsFormSaving(false);
                                    });
                            })
                            .catch(() => {
                                setIsFormSaving(false);
                            });
                    },
                    () => {
                        setIsFormSaving(false);
                    }
                );
            }}
            additionalActions={[
                {
                    label: useI18n('dialog.cancel'),
                    onClick: () => {
                        setIsCreate(false);
                        setSearchAllPeople(false);
                        setQuery('');
                    },
                    variant: 'text'
                }
            ]}
        >
            {isCreate ? (
                <GuestEditor
                    isInModal={true}
                    productionRef={productionRef}
                    entityIdFromModal={constants.createId}
                    setIsSaveDisabled={setIsSaveDisabled}
                    ref={guestEditorRef}
                    prePopulateName={{ name: extractNames(query) }}
                />
            ) : (
                <>
                    <SearchPanel
                        id={'people-serach'}
                        query={query}
                        setQuery={setQuery}
                        loading={false}
                        sx={{ width: '100%', p: 1 }}
                        searchPlaceholder={useI18n('production.header.form.searchKeyPeople')}
                    />
                    {searchAllPeople && query ? (
                        <ApolloProvider client={searchClient}>
                            <SearchkitProvider client={searchkit}>
                                <ListView
                                    key={query}
                                    id="accout_people"
                                    entity="Person"
                                    feature="People Resources"
                                    uri={undefined}
                                    query={slimResultsQuery('Person')}
                                    EntityListRenderer={(props) => {
                                        return (
                                            <List
                                                {...props}
                                                emptyListElement={
                                                    <KeyPeopleActions
                                                        onFirstAction={() => {
                                                            setIsCreate(true);
                                                        }}
                                                        onSecondAction={() => {
                                                            setSearchAllPeople(false);
                                                            setQuery('');
                                                        }}
                                                        firstActionChildren={
                                                            <I18n
                                                                token="form.person.createNewPerson"
                                                                values={{ name: query }}
                                                            />
                                                        }
                                                        secondActionChildren={
                                                            <I18n token="production.header.form.resetSearch" />
                                                        }
                                                    />
                                                }
                                                peopleWithKey={peopleWithKey}
                                                productionId={productionId}
                                                setSearchAllPeople={setSearchAllPeople}
                                                setIsCreate={setIsCreate}
                                                setQuery={setQuery}
                                            />
                                        );
                                    }}
                                    preFilters={preFilters}
                                    showBreadCrumbs={false}
                                    containerSx={{ height: '100%', m: 0, p: 1 }}
                                    facetsVisibility={{ filters: false, searchPanel: true, promotedFacets: false, quickFilter: false }}
                                    initialSearchQuery={query}
                                />
                            </SearchkitProvider>
                        </ApolloProvider>
                    ) : (
                        <V
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                pb: 2,
                                width: '100%',
                                height: '100%',
                                overflow: 'auto'
                            }}
                        >
                            <DataList
                                items={filteredPeopleList}
                                onClick={undefined}
                                disableRipple={true}
                                ItemRenderer={(props) => {
                                    return (
                                        <KeyPeopleTile
                                            {...props}
                                            isLoading={isPersonSaving}
                                            onMarkClick={(person) => {
                                                setIsPersonSaving({ id: person.personReference.id, isSaving: true });
                                                saveKeyPeople(
                                                    productionId,
                                                    getKeyPeopleInput(peopleWithKey, person, undefined),
                                                    true
                                                ).finally(() => {
                                                    //set timeout is required until issue with eventual consistency is fixed on service
                                                    setTimeout(() => {
                                                        client
                                                            .refetchQueries({ include: ['Production'] })
                                                            .finally(() => {
                                                                setIsPersonSaving({ id: '', isSaving: false });
                                                            });
                                                    }, 300);
                                                });
                                            }}
                                            onUnmarkClick={(person) => {
                                                setIsPersonSaving({ id: person.personReference.id, isSaving: true });
                                                saveKeyPeople(
                                                    productionId,
                                                    getKeyPeopleInput(peopleWithKey, undefined, person),
                                                    true
                                                ).finally(() => {
                                                    //set timeout is required until issue with eventual consistency is fixed on service
                                                    setTimeout(() => {
                                                        client
                                                            .refetchQueries({ include: ['Production'] })
                                                            .finally(() => {
                                                                setIsPersonSaving({ id: '', isSaving: false });
                                                            });
                                                    }, 300);
                                                });
                                            }}
                                        />
                                    );
                                }}
                            />
                        </V>
                    )}
                </>
            )}
        </FormModal>
    );
};
