import { TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { V } from '../Layout';
import { useStyling } from '../Theme';
import Loading from './Loading';

export default ({
    value,
    triggerUploadDialog = false,
    setOpenUploadDialog,
    onChange,
    onDropRead,
    placeholder,
    converter,
    onError,
    accept,
    setIsDataImported = undefined,
    disableTextBasedSelector,
    colorActive = 'lime'
}) => {
    const { isDarkMode } = useStyling();
    const [isUploading, setIsUploading] = useState(false);

    const onDropRejected = (files) => {
        if (files && files.length) {
            onError(`Drop rejected for files: ${files.map((file) => file.name).join(',')}`);
        }
    };

    const onDrop = (files) => {
        if (files && files.length) {
            console.log(`Drop accepted for files: ${files.map((file) => file.name).join(',')}`);
            const file = files[0];
            setIsUploading(true);

            converter(file)
                .then((res) => {
                    if (res.data && Array.isArray(res.data.records)) {
                        const newText = res.data.records.map((line) => line.data.join(',')).join('\n');
                        onDropRead(newText);
                    }
                })
                .catch((err) => {
                    console.error('File upload error:', err);
                    onError('File upload error: ' + err.message);
                })
                .finally(() => {
                    setIsUploading(false);
                    if (disableTextBasedSelector) {
                        setIsDataImported(true);
                    }
                });
        }
        setOpenUploadDialog(false);
    };

    const acceptExt = useMemo(() => {
        let ext = [];
        Object.values(accept).forEach((arr) => (ext = ext.concat(arr)));
        return ext;
    }, [accept]);

    const onFileDialogCancel = () => {
        setOpenUploadDialog(false);
    };

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop,
        onDropRejected,
        onFileDialogCancel,
        noKeyboard: true,
        noClick: true,
        multiple: false,
        // useFsAccessApi: false, commented out as it is not helping automation tests
        accept: acceptExt
    });

    useEffect(() => {
        if (typeof open === 'function' && triggerUploadDialog) {
            open();
        }
    }, [triggerUploadDialog]);

    const targetTextArea = (
        <TextField
            disabled={isUploading}
            defaultValue={value}
            onChange={onChange}
            className={'dropzone-drop'}
            sx={{ flexGrow: 2, height: '100%', width: '100%' }}
            hiddenLabel={true}
            multiline={true}
            rows={10}
            variant={'outlined'}
            autoFocus={false}
            fullWidth={true}
            InputProps={{
                inputProps: {
                    placeholder: placeholder,
                    sx: {
                        flexGrow: 2,
                        resize: 'vertical',
                        overflowY: 'auto',
                        minHeight: '20px',
                        height: '100%'
                    }
                }
            }}
        />
    );

    return (
        <V
            sx={{
                '& .MuiOutlinedInput-root': { flexGrow: 2 },
                '& .MuiInputBase-input': { height: '100% !important' },
                backgroundColor: isDragActive ? (isDarkMode ? grey['700'] : grey['300']) : 'none',
                color: isDragActive ? (isDarkMode ? grey['500'] : grey['500']) : 'inherit',
                height: '100%',
                overflow: 'hidden',
                backgroundImage: isDragActive
                    ? 'url("https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/upload-512.png")'
                    : 'none',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '64px 64px'
            }}
        >
            <div className="dropzone-container" style={{ height: '100%', width: '100%' }} {...getRootProps()}>
                <input className="dropzone-hidden" {...getInputProps()} />
                {isUploading ? <Loading /> : targetTextArea}
            </div>
        </V>
    );
};
