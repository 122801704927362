import React from 'react';
import { Box, Button, darken, lighten, Modal, Paper, Typography } from '@mui/material';
import { useStyling } from '../Theme';
import showrunnrLogoDark from '../logo/logo_dark.svg';
import showrunnrLogoLight from '../logo/logo_light.svg';
import { V } from '../Layout';
import I18n from '../i18n/I18n';
import { orange } from '@mui/material/colors';
import useHistory from '../utils/useHistory';
import { authLinks } from './AccountLinks';

const LIST_TEXT = [
    'createAccount.modal.listText.line1',
    'createAccount.modal.listText.line2',
    'createAccount.modal.listText.line3'
];

export default ({ message }) => {
    const { theme, isDarkMode } = useStyling();
    const { changeRoute, searchParams } = useHistory();
    const email = searchParams.get('username');

    return (
        <Modal open={true}>
            <Box
                sx={{
                    position: 'absolute',
                    flexDirection: 'column',
                    maxHeight: { xs: '100%', sm: '90%' },
                    boxShadow: { xs: 0, sm: 24 },
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    display: 'flex',
                    boxSizing: 'border-box',
                    borderRadius: { xs: 0, sm: 1 },
                    zIndex: 10,
                    width: { xs: '100%', sm: '580px' },
                    height: { xs: '100%', sm: '550px' },
                    bgcolor: isDarkMode ? darken(theme.palette.grey[700], 0.5) : lighten(orange[50], 0.7),
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: { xs: '40px', sm: '70px' },
                    gap: 2.5
                }}
            >
                {message}
                <Paper
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        padding: '40px',
                        gap: 2
                    }}
                >
                    <Typography variant="h4" sx={{ fontWeight: 'bold', color: theme.palette.secondary.main }}>
                        <I18n token="createAccount.modal.title" />
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                        <I18n token="createAccount.modal.subtitle1" />
                    </Typography>

                    <V sx={{ gap: 1 }}>
                        {LIST_TEXT.map((item) => (
                            <Typography fontSize="small" component="span" sx={{ alignSelf: 'start' }}>
                                • <I18n token={item} />
                            </Typography>
                        ))}
                    </V>

                    <Button
                        variant="contained"
                        sx={{ width: 'fit-content', alignSelf: 'center', position: 'relative' }}
                        onClick={() => {
                            changeRoute(authLinks.login.path, {}, true, { email });
                        }}
                    >
                        <I18n token="createAccount.modal.button" />
                    </Button>
                </Paper>
                <img
                    alt="Logo"
                    style={{ width: 'unset', height: '20px' }}
                    src={isDarkMode ? showrunnrLogoDark : showrunnrLogoLight}
                />
            </Box>
        </Modal>
    );
};
