import { ApolloClient, createHttpLink, DocumentNode, gql, InMemoryCache } from '@apollo/client';
import { authLink } from '../common/auth/api';
import {
    InquiryInput,
    LineItem,
    LineItemInput,
    Reference,
    Shortlist,
    ShortlistInput
} from 'sr-types/lib/shortlist/v1/graphql';
import { DateRangeFragment, ReferenceFragment } from '../common/list/fragments';
import { InviteFormData } from './form/PersonInviteForm';
import { InquiryStatusEnum } from './useShortlistLineItemsColDefs';
import { LineItemRows } from './ShortlistLineItems';
import { OrganizationInviteFormData } from './form/OrganizationInviteForm';
import Validator from '../common/form/Validator';
import { get, pick } from 'lodash';

export const client = new ApolloClient({
    link: authLink.concat(
        createHttpLink({
            uri: '/shortlist/v1/'
        })
    ),
    cache: new InMemoryCache({
        addTypename: false
    })
});

export type ReferenceWithLineItemId = Reference & { shortlistLineItemId: string };

export const saveQuery: DocumentNode = gql`
    mutation SaveShortlist($input: ShortlistInput!) {
        saveShortlist(input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const detailsQuery: DocumentNode = gql`
    query Shortlist($shortlistId: ID!) {
        shortlist(id: $shortlistId) {
            identity {
                id
            }
            name
            summary {
                about
                headline
            }
            context {
                ...Reference
            }
            type
            categories
            roles
            sequence {
                id
                type
                label
                uriPrefix
            }
            lineItems(page: { from: 0, size: 100 }) {
                name
                identity {
                    id
                }
                itemReference {
                    ...Reference
                }
                summary {
                    about
                    headline
                }
                itemStatus
                inquiry {
                    productionName
                    role
                    synopsis
                    message
                    inquiryStatus
                    assignmentPeriod {
                        ...DateRange
                    }
                    proposedDate {
                        ...DateRange
                    }
                    locations {
                        label
                        primary
                    }
                }
                defaultNoteReference {
                    id
                }
                hasAccount
                isItemSharedInDirectory
            }
        }
    }
    ${DateRangeFragment}
    ${ReferenceFragment}
`;

export const deleteQuery: DocumentNode = gql`
    mutation DeleteShortlist($id: ID!) {
        deleteShortlist(id: $id) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const addItemToShortlist: DocumentNode = gql`
    mutation AddItemToShortlist($id: ID!, $input: ItemInput!) {
        addItemToShortlist(id: $id, input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const saveLineItem: DocumentNode = gql`
    mutation SaveLineItem($shortlistId: ID!, $input: LineItemInput!) {
        saveLineItem(shortlistId: $shortlistId, input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const saveLineItemWithNote: DocumentNode = gql`
    mutation SaveLineItemWithNote($shortlistId: ID!, $input: LineItemInput!, $note: NoteInput) {
        saveLineItemWithNote(shortlistId: $shortlistId, input: $input, note: $note) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const deleteLineItemFromShortlist: DocumentNode = gql`
    mutation deleteLineItem($shortlistId: ID!, $lineItemId: ID!) {
        deleteLineItem(shortlistId: $shortlistId, lineItemId: $lineItemId) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const EMPTY_SHORTLIST: ShortlistInput = {
    identity: { id: '' },
    name: '',
    roles: [],
    categories: [],
    summary: { about: '', headline: '' },
    type: '',
    sequence: undefined
};

export const SendInquiries: DocumentNode = gql`
    mutation sendInquiries($inquiries: [InquiryInput]!) {
        sendInquiries(inquiries: $inquiries) {
            id
            errors {
                field
                key
                message
            }
        }
    }
`;

const commonInquiryInputObj = (editState: any, inquiryFor: string) => {
    return {
        assignmentPeriod: {
            duration: editState.dateRange.duration,
            durationType: editState.dateRange.durationType,
            end: editState.dateRange.end,
            endTime: editState.dateRange.endTime,
            recurrenceRule: editState.dateRange.recurrenceRule,
            start: editState.dateRange.start,
            startTime: editState.dateRange.startTime
        },
        locations: editState.locations,
        message: editState.personalizedMessage,
        resources: [
            {
                reference: editState.resource,
                name: editState.resource.label,
                category: editState.resourceCategory,
                isPrimary: true
            }
        ],
        InquiryFor: inquiryFor
    };
};

export const toInquiryInput = (editState: InviteFormData): InquiryInput[] => {
    const commonInquiryInputItems = commonInquiryInputObj(editState, 'individual');
    const inquiryInput: InquiryInput[] = editState.people.map((person) => {
        return {
            shortlistLineItemId: person.shortlistLineItemId,
            inquiryPayload: {
                ...commonInquiryInputItems,
                inquiryRecipient: person.contact,
                production: {
                    name: editState.production.name,
                    reference: editState.production.reference,
                    summary: editState.synopsis,

                    productionType: editState.production.productionType
                },
                role: editState.role
            }
        };
    }) as InquiryInput[];

    return inquiryInput;
};

export const toOrgInquiryInput = (editState: OrganizationInviteFormData): InquiryInput[] => {
    const activities = editState?.activities.length
        ? editState.activities.map((activity) => {
              return {
                  name: activity.label,
                  reference: activity
              };
          })
        : [];
    const commonInquiryInputItems = commonInquiryInputObj(editState, 'vendor');
    const inquiryInput: InquiryInput[] = editState.organizations.map((organization) => {
        const updatedInquiryInput = {
            shortlistLineItemId: organization.shortlistLineItemId,
            inquiryPayload: {
                ...commonInquiryInputItems,
                inquiryRecipient: organization.contact,
                production: {
                    name: editState.production.name,
                    reference: editState.production.reference,
                    summary: editState.productionSummary,
                    activities: activities,
                    productionType: editState.production.productionType
                },
                resourceNote: {
                    content: editState.resourceNote.text,
                    reference: editState.resourceNote.entityReference
                }
            }
        };

        return updatedInquiryInput;
    }) as InquiryInput[];

    return inquiryInput;
};

export const inquiriesInputToShortlist = (inquiriesInput: InquiryInput[], prevShortlistState: Shortlist): Shortlist => {
    const { lineItems } = prevShortlistState;

    const updatedLineItems: LineItem[] = lineItems.map((lineItem) => {
        const found = inquiriesInput.find((item) => item.shortlistLineItemId === lineItem.identity.id);

        if (found) {
            return {
                ...lineItem,
                inquiry: { ...found.inquiryPayload, inquiryStatus: InquiryStatusEnum.requestSent }
            };
        }
        return lineItem;
    });

    return { ...prevShortlistState, lineItems: updatedLineItems };
};

export const deleteItemFromState = (lineItemId: string, prevShortlistState: Shortlist): Shortlist => {
    const { lineItems } = prevShortlistState;
    lineItems.splice(
        lineItems.findIndex((i) => i.identity.id === lineItemId),
        1
    );
    return {
        ...prevShortlistState,
        lineItems
    };
};

export const lineItemInputToShortlist = (
    lineItemEditState: LineItemInput,
    lineItemId: string,
    prevShortlistState: Shortlist,
    note: string
): Shortlist => {
    const { lineItems } = prevShortlistState;
    const updatedLineItems = [...lineItems];

    const found = updatedLineItems.findIndex((item) => item.identity.id === lineItemId);
    if (found !== -1) {
        updatedLineItems[found] = {
            ...updatedLineItems[found],
            ...lineItemEditState,
            identity: { id: lineItemId },
            noteExists: !!note
        } as LineItemRows;
    } else {
        updatedLineItems.push({
            ...lineItemEditState,
            identity: { id: lineItemId },
            noteExists: !!note
        } as LineItemRows);
    }

    return { ...prevShortlistState, lineItems: updatedLineItems };
};

export const setEmptyContact = (
    selectedItem: any,
    itemDetails: any,
    setItemsWithNoEmail: React.Dispatch<React.SetStateAction<{}>>
) => {
    const itemDetailsWithNoContact = [];
    selectedItem.contact = {
        contactInfo: {
            email: {
                address: '',
                typeString: 'Business',
                verified: false
            }
        }
    };

    itemDetailsWithNoContact.push(itemDetails);
    setItemsWithNoEmail(itemDetailsWithNoContact);
};

export const setContactValidation = (
    index: number,
    name: string,
    validations: any,
    setValidations: React.Dispatch<React.SetStateAction<{}>>
) => {
    validations[`${name}[${index}].contact.contactInfo.email.address`] = [
        Validator.RULES.isRequired,
        Validator.RULES.email
    ];
    setValidations(validations);
};

export const checkForEmail = (
    data: any,
    nameInState: string,
    state: any,
    setValidations: React.Dispatch<React.SetStateAction<{}>>,
    setIsEmailModalOpen: React.Dispatch<React.SetStateAction<{}>>,
    setItemsWithNoEmail: React.Dispatch<React.SetStateAction<{}>>
) => {
    const selectedItems = get(state, nameInState);
    const validations = {};

    selectedItems.forEach((selectedItem, index) => {
        const itemDetails = data.find((item) => selectedItem.id === item.id);
        const isEmailUnavailableInCrew =
            itemDetails.reference.type === 'Person' && !itemDetails?.contactInfo?.email.address;

        const isEmailUnavailableInOrganization =
            itemDetails.reference.type !== 'Person' && !itemDetails?.contact?.contactInfo?.email.address;

        if (isEmailUnavailableInCrew || isEmailUnavailableInOrganization) {
            setEmptyContact(selectedItem, itemDetails, setItemsWithNoEmail);
            setContactValidation(index, nameInState, validations, setValidations);
            setIsEmailModalOpen(true);
        } else {
            itemDetails.contact = pick(
                itemDetails.contact,
                'name',
                'isPrimary',
                'contactInfo',
                'role',
                'personReference'
            );
            setIsEmailModalOpen(false);
        }
    });
};
