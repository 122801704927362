import { Box, Button, Container, List, ListItemButton, ListItemText, Paper } from '@mui/material';
import _ from 'lodash';
import * as React from 'react';
import I18n from '../i18n/I18n';
import { FormContainer } from '../form/FormContainer';
import FormItem from '../form/FormItem';
import FormWidget from '../form/FormWidget';
import { AuthFormHeader } from './AccountLinks';
import { ScrollableLayout } from '../Layout';

export default ({
    chooseAccountTitle,
    values,
    valid,
    hasErrors,
    onChange,
    orgAccounts,
    handleSelectAccount,
    cancel
}) => {
    return (
        <Container key="login" sx={{ overflowY: 'auto', height: '100%', maxWidth: 500 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Paper
                    sx={{
                        maxWidth: '400px',
                        p: 4,
                        minHeight: '500px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <FormContainer>
                        <FormItem>
                            <AuthFormHeader title={chooseAccountTitle} />
                        </FormItem>
                        <FormItem>
                            <FormWidget
                                name="username"
                                disabled
                                label={<I18n token="account.form.email" />}
                                value={values.username}
                                errors={valid.errors.username}
                                hasErrors={hasErrors('username')}
                                onChange={onChange}
                            />
                        </FormItem>
                        <FormItem>
                            {orgAccounts && orgAccounts.length > 1 && (
                                <ScrollableLayout height="200px" sx={{ p: 0 }}>
                                    <List sx={{ width: '100%' }}>
                                        {orgAccounts.map((orgAcc, index) => {
                                            return (
                                                <ListItemButton
                                                    sx={{ p: 0, pl: 1, pr: 1 }}
                                                    key={index}
                                                    onClick={() => handleSelectAccount(orgAcc)}
                                                >
                                                    <ListItemText
                                                        primary={orgAcc.name}
                                                        secondary={orgAcc?.organizationDomain?.pattern}
                                                    />
                                                </ListItemButton>
                                            );
                                        })}
                                    </List>
                                </ScrollableLayout>
                            )}
                        </FormItem>
                        <FormItem>
                            <Button sx={{ width: '100%' }} variant={'contained'} id="cancelButton" onClick={cancel}>
                                <I18n token={'dialog.back'} />
                            </Button>
                        </FormItem>
                    </FormContainer>
                </Paper>
            </Box>
        </Container>
    );
};
