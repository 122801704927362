import React, { useContext } from 'react';
import { FormContainer } from '../../common/form/FormContainer';
import { FormContext } from '../../common/form/FormContext';
import FormItem from '../../common/form/FormItem';
import FormPanel from '../../common/form/FormPanel';
import FormWidget from '../../common/form/FormWidget';
import { arrayToSelectOptions } from '../../common/utils/arrays';

const runtimes = ['30', '40', '60'];

export default () => {
    const { state } = useContext(FormContext);

    return (
        <FormPanel sx={{ width: '50%' }}>
            <FormContainer>
                <FormItem>
                    <FormWidget name="about" component={'TextArea'} label="Summary" minRows={2} />
                </FormItem>
                <FormItem half>
                    <FormWidget component="Date" name="releaseDate" label="Release Date" />
                </FormItem>
                <FormItem half>
                    <FormWidget component={'Number'} useGrouping={false} name="releaseYear" label="Release Year" />
                </FormItem>
                <FormItem half>
                    <FormWidget name="productionYears" label="Production Years" />
                </FormItem>
                <FormItem half>
                    <FormWidget
                        component="Autocomplete"
                        value={state.showAttributes.runtime}
                        name="showAttributes.runtime"
                        allowFreeForm={true}
                        label="Runtime(min)"
                        options={arrayToSelectOptions(runtimes)}
                    />
                </FormItem>
                <FormItem half>
                    <FormWidget component="Location" name="locations" label="Locations" multiple limitTags={1} />
                </FormItem>
            </FormContainer>
        </FormPanel>
    );
};
