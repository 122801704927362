import React, { useContext, useEffect, useState } from 'react';
import { FormContainer } from '../../common/form/FormContainer';
import FormItem from '../../common/form/FormItem';
import FormPanel from '../../common/form/FormPanel';
import FormWidget from '../../common/form/FormWidget';
import ScheduleWidget from '../../common/form/widgets/ScheduleWidget';
import I18n from '../../common/i18n/I18n';
import PersonOptionRenderer from './PersonOptionRenderer';
import ProductionTitleForm from './ProductionTitleForm';
import { FormContext } from '../../common/form/FormContext';

export default ({ data }) => {
    const [isOpen, toggleOpen] = useState(false);
    const { state, setState, handleChange } = useContext(FormContext);

    const [selectedValue, setSelectedValue] = useState('');
    const [savedValue, setSavedValue] = useState(data?.production?.name);
    const [productionOptions, setProductionOptions] = useState([...(data?.productionOptions || [])]);

    useEffect(() => {
        handleChange('production.name', savedValue);
    }, [savedValue]);

    return (
        <FormPanel>
            <FormContainer>
                <FormItem>
                    <FormWidget
                        disabled
                        name="people"
                        label={<I18n token="shortlist.lineItem.person.invitee" />}
                        component="ReferenceAutocomplete"
                        addOption
                        multiple
                        entity="Person"
                        OptionRenderer={PersonOptionRenderer}
                        getOption={(item) => {
                            return {
                                id: item.id,
                                name: item.name,
                                role: item.role,
                                label: item.name,
                                ref: { ...item.reference, label: item.name }
                            };
                        }}
                        preload={false}
                    />
                </FormItem>
            </FormContainer>
            <FormContainer>
                <FormItem half>
                    <FormWidget
                        name="production.name"
                        label={'Production'}
                        component="Autocomplete"
                        options={productionOptions}
                        data-testid={'production'}
                        editInputValue
                        selectOnFocus
                        allowFreeForm
                        renderOption
                        isAddEnabled
                        value={savedValue}
                        onChange={(name, value) => {
                            setSavedValue(value);
                        }}
                        setSelectedValue={setSelectedValue}
                        isCustomEelementOpen={isOpen}
                        toggleOpen={() => {
                            toggleOpen(true);
                        }}
                        disabled={isOpen}
                        onBlur={() => {
                            if (!savedValue && !state?.production.name) {
                                setSavedValue(data?.production?.name);
                            }
                        }}
                    />
                </FormItem>
                <FormItem half>
                    <FormWidget
                        name="role"
                        label={<I18n token="form.resource.role" />}
                        component="MetadataAutocomplete"
                        metadataName="Role"
                        disabled
                    />
                </FormItem>
            </FormContainer>
            <FormItem>
                {isOpen && (
                    <ProductionTitleForm
                        setSavedValue={setSavedValue}
                        setSelectedValue={setSelectedValue}
                        selectedValue={selectedValue}
                        setState={setState}
                        toggleOpen={toggleOpen}
                        setProductionOptions={setProductionOptions}
                        productionOptions={productionOptions}
                    />
                )}
            </FormItem>
            <FormContainer>
                <FormItem half>
                    <FormWidget
                        component="Location"
                        name="locations"
                        label={<I18n token="form.resource.locations" />}
                        multiple
                        limitTags={1}
                    />
                </FormItem>
            </FormContainer>
            <FormContainer>
                <FormItem>
                    <FormWidget
                        name="synopsis"
                        component={'TextArea'}
                        label={<I18n token="shortlist.lineItem.person.synopsis" />}
                        minRows={3}
                    />
                </FormItem>
            </FormContainer>
            <FormContainer>
                <FormItem>
                    <FormWidget
                        hideHelperText
                        component={'Switch'}
                        name="dateRange.durationSpecified"
                        label={<I18n token="form.shortlistLineItem.inquiry.durationSpecified" />}
                    />
                </FormItem>
                <ScheduleWidget showFullSchedule={true} name="dateRange" allowedUnits={['hour', 'day', 'week']} />
            </FormContainer>
            <FormContainer>
                <FormItem>
                    <FormWidget
                        name="personalizedMessage"
                        component={'TextArea'}
                        label={<I18n token="shortlist.lineItem.person.personalizedMessage" />}
                        minRows={3}
                    />
                </FormItem>
            </FormContainer>
        </FormPanel>
    );
};
