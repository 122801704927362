import { useContext, useMemo } from 'react';
import { MARKETPLACE, PLANNING, PROFILES, SUPPLIER } from '../../common/nav/apps';
import { UserContext } from '../../common/auth/UserContext';
import { toLocaleDateString } from '../../common/utils/dateTime';
import { H } from '../../common/Layout';
import { IconButton } from '@mui/material';
import { Icons } from '../../common/icons/Icons';
import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { useI18n } from '../../common/i18n/I18n';
import { useStyling } from '../../common/Theme';

export default (isProductionMode: boolean, onNotesClick: (row) => void, onAttachmentsClick: (row) => void) => {
    const { application } = useContext(UserContext);
    const { theme } = useStyling();

    const headerNames = {
        title: useI18n('grid.header.title'),
        aka: useI18n('grid.header.AKA'),
        type: useI18n('grid.header.type'),
        status: useI18n('grid.header.status'),
        associatedTitle: useI18n('grid.header.associated.title'),
        releaseDate: useI18n('grid.header.release.date'),
        releaseYear: useI18n('grid.header.release.year'),
        productionYears: useI18n('grid.header.production.years'),
        season: useI18n('grid.header.season'),
        createdBy: useI18n('grid.header.createdBy'),
        createdOn: useI18n('grid.header.createdOn'),
        updatedOn: useI18n('grid.header.updatedOn')
    };

    const columnsAll = useMemo(() => {
        return {
            notes: {
                field: 'notes',
                headerName: '',
                minWidth: 30,
                width: 30,
                resizable: false,
                disableReorder: true,
                sortable: false,
                hide: isProductionMode,
                renderCell: (params: GridRenderCellParams) => {
                    return (
                        <H sx={{ gap: 0.5, justifyContent: 'center' }}>
                            <IconButton
                                size={'small'}
                                component="span"
                                onClick={() => {
                                    onNotesClick(params.row);
                                }}
                            >
                                <Icons.Notes fontSize={'small'} />
                            </IconButton>
                        </H>
                    );
                }
            },
            attachments: {
                field: 'attachments',
                headerName: '',
                minWidth: 30,
                width: 30,
                resizable: false,
                disableReorder: true,
                hide: isProductionMode,
                renderCell: (params: GridRenderCellParams) => {
                    return (
                        <H sx={{ gap: 0.5, justifyContent: 'center', color: `${theme.palette.text.disabled}` }}>
                            <IconButton
                                size={'small'}
                                component="span"
                                onClick={() => {
                                    onAttachmentsClick(params.row);
                                }}
                            >
                                <Icons.Attachments fontSize={'small'} />
                            </IconButton>
                        </H>
                    );
                }
            },
            managed: {
                field: 'managed',
                headerName: '',
                minWidth: 50,
                width: 50,
                resizable: false,
                disableReorder: true,
                hide: !isProductionMode,
                renderCell: (params) => {
                    return (
                        params.row.managedProduction && (
                            <H sx={{ gap: 0.5, justifyContent: 'center' }}>
                                <Icons.Workspaces color={'success'} fontSize={'small'} />
                            </H>
                        )
                    );
                }
            },
            name: { field: 'publictitle', headerName: headerNames.title, flex: 2, minWidth: 100 },
            aka: { field: 'otherTitles', headerName: headerNames.aka, flex: 1, minWidth: 100 },
            type: { field: 'productiontype', headerName: headerNames.type, flex: 1, minWidth: 100 },
            productionStatus: {
                field: 'productionStatus',
                headerName: headerNames.status,
                flex: 1,
                minWidth: 100
            },
            productionGroup: {
                field: 'productionGroup',
                headerName: headerNames.associatedTitle,
                flex: 1,
                hide: true,
                minWidth: 100
            },
            releaseDate: {
                field: 'releaseDate',
                headerName: headerNames.releaseDate,
                flex: 1,
                hide: true,
                minWidth: 100,
                type: 'date',
                valueFormatter: (params) => toLocaleDateString(params?.value)
            },
            releaseYear: {
                field: 'releaseYear',
                headerName: headerNames.releaseYear,
                flex: 1,
                hide: true,
                minWidth: 100
            },
            productionYears: {
                field: 'productionYears',
                headerName: headerNames.productionYears,
                flex: 1,
                minWidth: 100
            },
            season: {
                field: 'seasonNumber',
                headerName: headerNames.season,

                flex: 1,
                minWidth: 100
            },
            createdOn: {
                field: 'createdOn',
                headerName: headerNames.createdOn,
                flex: 1,
                type: 'date',
                minWidth: 100,
                valueFormatter: (params) => toLocaleDateString(params?.value)
            },
            updatedOn: {
                field: 'updatedOn',
                headerName: headerNames.updatedOn,
                type: 'date',
                flex: 1,
                minWidth: 100,
                valueFormatter: (params) => toLocaleDateString(params?.value)
            },
            createdByName: {
                field: 'createdByName',
                headerName: headerNames.createdBy,
                flex: 1,
                minWidth: 100
            }
        };
    }, []);

    const columnsByApp = useMemo(() => {
        return {
            [MARKETPLACE.id]: [
                columnsAll.attachments,
                columnsAll.notes,
                columnsAll.managed,
                columnsAll.name,
                columnsAll.aka,
                columnsAll.type,
                columnsAll.productionStatus,
                columnsAll.productionGroup,
                columnsAll.releaseDate,
                columnsAll.releaseYear,
                columnsAll.productionYears,
                columnsAll.season,
                columnsAll.createdOn,
                columnsAll.updatedOn,
                columnsAll.createdByName
            ],
            [SUPPLIER.id]: [
                columnsAll.attachments,
                columnsAll.notes,
                columnsAll.managed,
                columnsAll.name,
                columnsAll.aka,
                columnsAll.type,
                columnsAll.productionStatus,
                columnsAll.productionGroup,
                columnsAll.releaseDate,
                columnsAll.releaseYear,
                columnsAll.productionYears,
                columnsAll.season,
                columnsAll.createdOn,
                columnsAll.updatedOn,
                columnsAll.createdByName
            ],
            [PLANNING.id]: [
                columnsAll.attachments,
                columnsAll.notes,
                columnsAll.managed,
                columnsAll.name,
                columnsAll.aka,
                columnsAll.type,
                columnsAll.productionStatus,
                columnsAll.productionGroup,
                columnsAll.releaseDate,
                columnsAll.releaseYear,
                columnsAll.productionYears,
                columnsAll.season,
                columnsAll.createdOn,
                columnsAll.updatedOn,
                columnsAll.createdByName
            ],
            [PROFILES.id]: [
                columnsAll.attachments,
                columnsAll.notes,
                columnsAll.managed,
                columnsAll.name,
                columnsAll.aka,
                columnsAll.type,
                columnsAll.productionStatus,
                columnsAll.productionGroup,
                columnsAll.releaseDate,
                columnsAll.releaseYear,
                columnsAll.productionYears,
                columnsAll.season,
                columnsAll.createdOn,
                columnsAll.updatedOn,
                columnsAll.createdByName
            ]
        };
    }, []);

    return columnsByApp[application.id];
};
