import React, { useContext, useState } from 'react';
import FormWidget from '../../common/form/FormWidget';
import I18n from '../../common/i18n/I18n';
import { Button, Typography } from '@mui/material';
import { H } from '../../common/Layout';
import { useMutation } from '@apollo/client';
import { client, saveQuery } from '../../production/helpers/production';
import { UserContext } from '../../common/auth/UserContext';
import useHistory from '../../common/utils/useHistory';
import { toInput, useProductionDetailsData } from '../../production/helpers/productionApi';
import { FormProvider } from '../../common/form/FormContext';
import { useStyling } from '../../common/Theme';
import { LoadingButton } from '@mui/lab';

export default ({
    toggleOpen,
    setState,
    selectedValue,
    setSelectedValue,
    setSavedValue,
    setProductionOptions,
    productionOptions
}) => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const { routeParams } = useHistory();
    const { productionId } = routeParams;
    const { productionDetailsData } = useProductionDetailsData(productionId);
    const { theme, isDarkMode } = useStyling();
    const [alternateNameState, setAlternateNameState] = useState(undefined);
    // Waiting for confirmation if we are going with these colors or revert to default.
    const lightBorder = 'rgba(0,17,168,0.3)';
    const darkBorder = '#6e6e6e';
    const lightBg = 'rgba(0,17,168,0.04)';
    const borderColor = isDarkMode ? darkBorder : lightBorder;
    const bgColor = isDarkMode ? theme.palette.background.paper : lightBg;
    const [loading, setLoading] = useState(false);
    const [save] = useMutation(saveQuery, {
        client,
        context: { headers: { ownerId: activeOrganizationAccount } }
    });

    const handleConfirm = () => {
        const newOption = {
            key: alternateNameState.alternateLabel,
            value: selectedValue
        };
        setProductionOptions((previous) => [
            ...previous,
            { key: alternateNameState.alternateLabel, label: selectedValue }
        ]);
        setState((prevState) => {
            const updatedOptions = [...prevState.productionOptions, newOption];

            return {
                ...prevState,
                productionOptions: updatedOptions
            };
        });
        if (productionDetailsData) {
            const productionDetails = toInput(productionId, productionDetailsData.production);
            const otherTitles = [];
            if (productionDetailsData.production.otherTitles?.length) {
                otherTitles.push(...productionDetailsData.production.otherTitles);
            }
            otherTitles.push(newOption);
            save({
                variables: {
                    input: {
                        ...productionDetails,
                        otherTitles
                    }
                }
            }).then(() => {
                toggleOpen(false);
                setLoading(false);

                setSavedValue(selectedValue);
            });
        }
    };

    return (
        <H
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                borderWidth: '1px',
                borderRadius: '12px',
                borderColor: borderColor,
                borderStyle: 'solid',
                backgroundColor: bgColor,
                padding: 2
            }}
        >
            <Typography fontWeight={'bold'} textAlign={'start'} width={'100%'}>
                <I18n token="add.alternate.name" />
            </Typography>

            <H sx={{ width: '100%', gap: 2, marginTop: 2 }}>
                <FormProvider state={alternateNameState} setState={setAlternateNameState}>
                    <FormWidget
                        sx={{
                            '& input': {
                                backgroundColor: theme.palette.background.paper,
                                borderRadius: '12px'
                            }
                        }}
                        name="alternateName"
                        label={<I18n token="form.name" />}
                        value={selectedValue}
                        onChange={(name, value) => setSelectedValue(value)}
                    />

                    <FormWidget
                        sx={{
                            '& input': {
                                backgroundColor: theme.palette.background.paper,
                                borderRadius: '12px'
                            }
                        }}
                        name="alternateLabel"
                        label={<I18n token="form.label" />}
                    />
                </FormProvider>
            </H>
            <H sx={{ gap: 2, justifyContent: 'flex-end' }}>
                <Button
                    variant="text"
                    onClick={() => {
                        toggleOpen(false);
                    }}
                >
                    <I18n token={'dialog.cancel'} />
                </Button>

                <LoadingButton
                    onClick={() => {
                        setLoading(true);
                        handleConfirm();
                    }}
                    loading={loading}
                    variant={'contained'}
                >
                    <I18n token={'form.button.save.and.add'} />
                </LoadingButton>
            </H>
        </H>
    );
};
