import { Button, IconButton, Popover, TextField } from '@mui/material';
import { SxProps } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import { useStyling } from '../../../common/Theme';
import Loading from '../../../common/components/Loading';
import { useIsWithinBreakpoints } from '../../../common/hooks/useIsWithinBreakpoints';
import { useI18n } from '../../../common/i18n/I18n';
import { Icons } from '../../../common/icons/Icons';
import { StyledBadge } from './StyledBadge';

const SearchInput = ({ id, loading, query, setQuery, sx = undefined, searching, searchPlaceholder }) => {
    const [term, setTerm] = useState<string>(query);
    const { theme } = useStyling();
    const ref = useRef<any>();
    const buttonSx: SxProps = {
        ml: 1,
        '&:hover': { cursor: 'pointer' },
        fontColor: loading ? theme.palette.text.disabled : theme.palette.text.primary
    };
    const placeholder = useI18n('filter.search.label');

    useEffect(() => {
        setTerm(query);
    }, [query]);

    return (
        <TextField
            sx={sx}
            id={`${id}-input`}
            inputRef={ref}
            // sx={{ width: '200px' }}
            value={term}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setTerm(e.target.value);
            }}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    setQuery(term);
                }
            }}
            variant={'outlined'}
            size={'small'}
            InputProps={{
                sx: { height: '32px' },
                endAdornment: (
                    <>
                        {searching ? (
                            <Loading size="16px" sx={{ width: 'unset' }} />
                        ) : (
                            <Icons.Search
                                sx={buttonSx}
                                onClick={(e) => {
                                    setQuery(term);
                                    // if (ref && ref.current) {
                                    //     ref.current.focus();
                                    // }
                                }}
                            />
                        )}
                        <Icons.Close
                            sx={buttonSx}
                            onClick={(e) => {
                                setTerm('');
                                setQuery('');
                                // if (ref && ref.current) {
                                //     ref.current.focus();
                                // }
                            }}
                        />
                    </>
                ),
                autoFocus: false,
                inputProps: {
                    placeholder: searchPlaceholder || placeholder
                }
            }}
        />
    );
};

const MobileButton = ({ id, loading, query, setQuery, searching, searchPlaceholder }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const showLabel = useIsWithinBreakpoints(['s']);
    const label = useI18n('facets.search.label');
    const { theme } = useStyling();

    const onClose = () => {
        setAnchorEl(null);
    };
    const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(e.currentTarget);
    };
    return (
        <>
            <StyledBadge>
                {showLabel ? (
                    <Button sx={{ whiteSpace: 'nowrap' }} onClick={onClick} variant="text" startIcon={<Icons.Search />}>
                        {label}
                    </Button>
                ) : (
                    <IconButton
                        sx={{
                            color: theme.palette.primary.main,
                            px: 0.5,
                            py: 0
                        }}
                        onClick={onClick}
                    >
                        <Icons.Search />
                    </IconButton>
                )}
            </StyledBadge>
            <Popover
                id={`${id}-mobile-popover`}
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                PaperProps={{
                    sx: {
                        overflow: 'hidden'
                    }
                }}
            >
                <SearchInput
                    searchPlaceholder={searchPlaceholder}
                    id={id}
                    loading={loading}
                    searching={searching}
                    query={query}
                    setQuery={(query) => {
                        // We have to do this 2-level kind of thing because we want to close the popover.
                        setQuery(query);
                        onClose();
                    }}
                />
            </Popover>
        </>
    );
};

type SearchPanelProps = {
    id: string;
    loading: boolean;
    query: string;
    setQuery?: (string) => void;
    sx?: SxProps;
    searching?: boolean;
    searchPlaceholder?: any;
};

export default (props: SearchPanelProps) => {
    const { id, loading, query, setQuery, sx = {}, searching = false, searchPlaceholder } = props;
    const isMobile = useIsWithinBreakpoints(['s', 'xs']);

    return typeof setQuery === 'function' ? (
        isMobile ? (
            <MobileButton
                id={id}
                loading={loading}
                searching={searching}
                query={query}
                setQuery={setQuery}
                searchPlaceholder={searchPlaceholder}
            />
        ) : (
            <SearchInput
                id={id}
                loading={loading}
                searching={searching}
                query={query}
                setQuery={setQuery}
                sx={sx}
                searchPlaceholder={searchPlaceholder}
            />
        )
    ) : (
        <></>
    );
};
