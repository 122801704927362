import { useEffect, useState } from 'react';
import { FormProvider } from '../../common/form/FormContext';
import OrganizationInviteFormItems from './OrganizationInviteFormItems';
import FormModal from '../../common/form/FormModal';
import React from 'react';
import I18n from '../../common/i18n/I18n';
import { useMutationWithContext } from '../../common/hooks/useMutationWithContext';
import {
    ReferenceWithLineItemId,
    SendInquiries,
    checkForEmail,
    client as shortlistClient,
    toOrgInquiryInput
} from '../shortlist';
import { DateRange, InquiryInput, Location, NoteInput, Reference } from 'sr-types/lib/shortlist/v1/graphql';
import { failedToastMessage, successToastMessage } from '../../common/utils/commonUtils';
import { Production } from 'sr-types/lib/production/v1/graphql';
import InquiryContactDetailsForm from './InquiryContactDetailsForm';
import { cloneDeep, isNil } from 'lodash';
import { Contact, Organization } from 'sr-types/lib/search/v1/graphql';
import Validator from '../../common/form/Validator';
import { useGetOrganizationDetailsForInquiry } from '../shortlistApi';
import { getDateRangeListeners, getDateRangeValidators } from '../../supply/booking/ScheduleCalculator';
import { client, detailsQuery } from '../../production/helpers/production';

export type organizationType = ReferenceWithLineItemId & {
    contact: Contact & { id?: string };
};

export type OrganizationInviteFormData = {
    organizations: organizationType[];
    resource: Reference;
    resourceCategory: string;
    locations: Location[];
    dateRange: DateRange;
    production: Production;
    productionOptions: { id: string; label: string; key?: string }[];
    activities: Reference[];
    productionSummary: string;
    personalizedMessage: string;
    resourceNote: NoteInput & { text: string };
};

export type OrganizationInvitePropType = {
    isOpen: boolean;
    onClose: () => void;
    data: OrganizationInviteFormData;
    onSuccess: (editState: InquiryInput[]) => void;
    filter: any;
};

export default ({ isOpen, onClose, data, onSuccess, filter }: OrganizationInvitePropType) => {
    const { organizationData, organizationLoading } = useGetOrganizationDetailsForInquiry(filter);
    const [values, setValues] = useState<OrganizationInviteFormData>();
    const [isSaving, setIsSaving] = useState(false);
    const [sendInquiry] = useMutationWithContext(SendInquiries, shortlistClient);
    const [organizationsWithNoEmail, setOrganizationsWithNoEmail] = useState<Organization[]>([]);
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(null);
    const [validations, setValidations] = useState({});

    useEffect(() => {
        if (data) {
            const values = cloneDeep(data);
            setValues({ ...values });
        }
    }, [data]);

    useEffect(() => {
        if (organizationData && organizationData.search?.hits.items) {
            const { items } = organizationData.search.hits;
            checkForEmail(
                items,
                'organizations',
                values,
                setValidations,
                setIsEmailModalOpen,
                setOrganizationsWithNoEmail
            );
        }
    }, [organizationData, values?.organizations]);

    const sendInvitation = () => {
        const inquiryInput: InquiryInput[] = toOrgInquiryInput(values);

        setIsSaving(true);
        sendInquiry({
            variables: { inquiries: inquiryInput }
        })
            .then((data: any) => {
                if (!data?.sendInquiries?.errors) {
                    successToastMessage('form.shortlistLineItem.inquiry.success');
                    onSuccess(inquiryInput);
                }
            })
            .catch(() => {
                failedToastMessage('form.shortlistLineItem.inquiry.error');
            })
            .finally(() => {
                setIsSaving(false);
                setTimeout(() => {
                    client.refetchQueries({
                        include: [detailsQuery]
                    });
                }, 2000);
            });
    };

    const onClickContinue = () => {
        setIsSaving(true);
        setValidations({
            ...getDateRangeValidators('dateRange'),
            locations: [Validator.RULES.isRequired],
            production: [Validator.RULES.isRequired]
        });
        setTimeout(() => {
            setIsEmailModalOpen(false);
            setIsSaving(false);
        }, 150);
    };

    return (
        <FormProvider
            state={values}
            setState={setValues}
            validationRules={validations}
            listeners={getDateRangeListeners('dateRange')}
        >
            <FormModal
                id={'org-inquiry'}
                title={<I18n token={'organization.inquiry.form.label'} />}
                isOpen={isOpen}
                onClose={onClose}
                onSave={isEmailModalOpen ? onClickContinue : sendInvitation}
                isLoading={organizationLoading}
                isSaving={isSaving}
                saveLabel={
                    isEmailModalOpen ? (
                        <I18n token="organization.add.contact.button.continue" />
                    ) : (
                        <I18n token={'organization.inquiry.form.send'} />
                    )
                }
            >
                {isNil(isEmailModalOpen) ? (
                    <></>
                ) : isEmailModalOpen ? (
                    <InquiryContactDetailsForm itemsWithNoContact={organizationsWithNoEmail} name={'organizations'} />
                ) : (
                    <OrganizationInviteFormItems data={data} />
                )}
            </FormModal>
        </FormProvider>
    );
};
