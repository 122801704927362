import React, { useCallback, useContext } from 'react';
import { Navigate, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { KeyValueMap } from '../KeyValueMap';
import { UserContext } from '../auth/UserContext';
import db from '../indexedDB';
import useDefaultRoute from '../nav/useDefaultRoute';

const showrunnr = 'showrunnr';

export const ChangeRoute = ({ path }) => {
    return (
        <Navigate
            to={path}
            state={{
                referrer: window.location?.pathname + window.location?.search
            }}
        />
    );
};

export default () => {
    const location: any = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const params: any = useParams();
    const nav = useNavigate();
    const { isAuthenticated, resetUserContext, userProfile } = useContext(UserContext);
    const { pathname, orgId } = useDefaultRoute();

    const mergeSearchParams = (query: KeyValueMap<string, string> | URLSearchParams, reset = false) => {
        const next = new URLSearchParams(reset ? '' : window.location.search);
        if (query) {
            const isURLSearchParams = query instanceof URLSearchParams;

            for (const [key, value] of isURLSearchParams ? query : Object.entries(query)) {
                if (value !== undefined && value !== null) {
                    next.set(key, value);
                } else {
                    next.delete(key);
                }
            }
        }
        return next;
    };

    const changeSearch = useCallback(
        (query: KeyValueMap<string, string> | URLSearchParams = undefined, reset = false) => {
            const next: URLSearchParams = mergeSearchParams(query, reset);
            setSearchParams(next);
        },
        [setSearchParams]
    );

    const changeRoute = useCallback(
        (url, query: KeyValueMap<string, string> | URLSearchParams = undefined, reset = false, locationState = {}) => {
            const next: URLSearchParams = mergeSearchParams(query, reset);
            return nav(
                {
                    pathname: url /*|| location.pathname*/,
                    search: next.toString()
                },
                {
                    state: {
                        referrer: location?.pathname + location?.search,
                        app: showrunnr,
                        ...locationState
                    }
                }
            );
        },
        [location, params]
    );

    const logOut = (setReferrer: boolean = true) => {
        const ref = localStorage.getItem(userProfile?.username ? 'referrer.' + userProfile.username : 'referrer');
        if (!ref && setReferrer) {
            const newRef = JSON.stringify({
                pathname: location.pathname,
                search: location.search,
                hash: location.hash
            });
            localStorage.setItem(userProfile?.username ? 'referrer.' + userProfile.username : 'referrer', newRef);
        }

        // TODO Do we need to do this?
        // const searchParams: URLSearchParams = new URLSearchParams(location.search);
        // if (searchParams.has('orgId')) {
        //     searchParams.set('orgId', undefined);
        //     location.search = searchParams.toString();
        // }

        Object.values(db._allTables).forEach((table) => {
            table.clear().then(() => {
                console.log('cleared', table.name);
            });
        });
        resetUserContext();
        changeRoute('/login', undefined, true);
    };

    /**
     * Go back but do it smart.
     */
    const goBack = useCallback(
        (defaultPathname = undefined) => {
            // If logged in route to default route otherwise just go to / which will resolve to login page.
            if (isAuthenticated) {
                const fromShowrunnr = location.state && location.state.app === showrunnr;
                // Where do we want to go if we are not coming from within showrunnr app?
                fromShowrunnr ? nav(-1) : changeRoute(defaultPathname || pathname, { orgId: orgId });
            } else {
                nav('/login');
            }
        },
        [isAuthenticated, location.state, nav, changeRoute, pathname, orgId]
    );

    return { location, routeParams: params, changeRoute, searchParams, changeSearch, goBack, logOut };
};
