import { GridColDef, GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid-premium';
import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { useFeaturePermissions, UserContext } from '../common/auth/UserContext';
import DataGrid from '../common/list/DataGrid';
import { enqueueSnackbar } from '../common/components/Toast';
import I18n, { useI18n } from '../common/i18n/I18n';
import Confirm from '../common/components/Confirm';
import { Button, Typography } from '@mui/material';
import ShortlistLineItemEditor from './ShortlistLineItemEditor';
import {
    useDeleteLineItemFromShortlist,
    useGetOrganizationDetailsForInquiry,
    useGetPersonDetailsForInquiry
} from './shortlistApi';
import { LineItem, Shortlist } from 'sr-types/lib/shortlist/v1/graphql';
import { V } from '../common/Layout';
import { Reference } from 'sr-types/lib/search/v1/graphql';
import WithSidePanel from '../common/components/layout/WithSidePanel';
import Notes from '../common/notes/Notes';
import { Icons } from '../common/icons/Icons';
import useShortlistLineItemsColDefs, {
    InquiryStatus,
    inquiryStatus,
    InquiryStatusEnum
} from './useShortlistLineItemsColDefs';
import { ALL_PERMISSIONS, AllPermissions } from '../common/auth/api';
import PersonInviteForm from './form/PersonInviteForm';
import { Production, Resource } from 'sr-types/lib/production/v1/graphql';
import { EMPTY_DATERANGE } from '../lab/form/LabForms';
import { deleteItemFromState, inquiriesInputToShortlist, lineItemInputToShortlist } from './shortlist';
import { getStringFromDateRange } from '../production/helpers/activityUtils';
import OrganizationInviteForm from './form/OrganizationInviteForm';
import { NoteInput } from 'sr-types/lib/note/v1/graphql';

export const handleShortlistLineItemResponse =
    ({ variant, query }: { variant: 'success' | 'error'; query: 'save' | 'delete' }) =>
    () =>
        enqueueSnackbar(<I18n token={`shortlist.line.items.${query}.${variant}`} />, { variant });

interface ShortlistLineItemsProps {
    shortlistId: string;
    viewState: Shortlist;
    setViewState: Dispatch<SetStateAction<Shortlist>>;
    resourceData?: Resource;
    productionData?: Production;
    refetchShortlistData?: () => void;
    resourceNote?: NoteInput;
}

type LineItemAdditionalFields = {
    inquiryStatus?: InquiryStatus | string;
    isPerson?: boolean;
    isOrganization?: boolean;
    id?: string | number;
    availability?: InquiryStatus | string;
};

export type LineItemRows = LineItem & LineItemAdditionalFields & { __reorder__: string; noteExists?: boolean };

export type LineItemExtended = LineItem & LineItemAdditionalFields;
export default ({
    shortlistId,
    viewState,
    setViewState,
    resourceData,
    productionData,
    refetchShortlistData,
    resourceNote
}: ShortlistLineItemsProps) => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const { deleteShortlistLineItem, isLineItemDeleting } = useDeleteLineItemFromShortlist(activeOrganizationAccount);
    const featurePermissions = useFeaturePermissions('Shortlist');
    const canInvite = useFeaturePermissions('Assess Avails')?.[AllPermissions.Manage];
    const entityPermissions = ALL_PERMISSIONS;

    const [isOpen, setIsOpen] = useState(false);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [lineItemIndex, setLineItemIndex] = useState(undefined);
    const [lineitemToDelete, setLineItemToDelete] = useState(undefined);
    const [entityReference, setEntityReference] = useState<Reference>();
    const [panelTitle, setPanelTitle] = useState<string>();
    const [showInviteButton, setShowInviteButton] = useState<boolean>(false);
    const [openInviteModal, setOpenInviteModal] = useState<boolean>(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const isTypeCrew = resourceData?.type === 'Crew';
    const [filter, setFilter] = useState([]);

    const onSuccessDeleteMessage = () => {
        handleShortlistLineItemResponse({ variant: 'success', query: 'delete' });
    };
    const onErrorDeleteMessage = () => {
        handleShortlistLineItemResponse({ variant: 'error', query: 'delete' });
    };
    const personalizedMessage = useI18n('shortlist.lineItem.person.message');

    const personalizedMessageForOrgInquiry = useI18n('shortlist.lineItem.inquiry.message', {
        organization: selectedItems?.map((item) => item.label).join(','),
        resourceCategory: resourceData?.resourceCategory
    });

    const columns: GridColDef[] = useShortlistLineItemsColDefs(
        (row) => {
            setEntityReference({
                deleted: false,
                eventId: '',
                id: row.identity.id,
                type: 'ShortlistLineItem',
                uriPrefix: 'lineItems'
            });
            setPanelTitle(`Notes for ${row['name']}`);
        },
        (row) => {
            setSelectedItems([{ ...row.itemReference, shortlistLineItemId: row.identity.id }]);
            setFilter([{ field: 'identity', value: { values: row.itemReference.id } }]);
            setOpenInviteModal(true);
        },
        !!(resourceData && productionData && canInvite)
    );

    const rows: LineItemRows[] = viewState?.lineItems
        ? viewState?.lineItems?.map((shortlistLineItem: LineItemExtended, index) => {
              const inquiryStatus = shortlistLineItem?.inquiry?.inquiryStatus;
              const proposedDate = shortlistLineItem?.inquiry?.proposedDate;
              const alternateProposed = `${inquiryStatus} (${!proposedDate?.end ? useI18n('lineItems.availability.availableFrom') + ' ' : ''}${getStringFromDateRange(proposedDate)})`;

              const availability = inquiryStatus
                  ? inquiryStatus === InquiryStatusEnum.alternateProposed && proposedDate
                      ? alternateProposed
                      : inquiryStatus
                  : InquiryStatusEnum.empty;

              return {
                  id: index,
                  ...shortlistLineItem,
                  isPerson: shortlistLineItem.itemReference?.type === 'Person',
                  isOrganization: shortlistLineItem.itemReference?.type === 'Organization',
                  inquiryStatus: shortlistLineItem?.inquiry?.inquiryStatus || InquiryStatusEnum.empty,
                  availability: availability,
                  __reorder__: shortlistLineItem.name
              };
          })
        : [];

    const deleteLineItem = () => {
        deleteShortlistLineItem({
            shortlistId,
            lineItemId: rows[lineitemToDelete]?.identity.id,
            onClose: () => setDeleteConfirmationOpen(false),
            onSuccess: () => {
                onSuccessDeleteMessage();
                setViewState((prevVal) => {
                    return deleteItemFromState(rows[lineitemToDelete]?.identity.id, prevVal);
                });
            },
            onError: onErrorDeleteMessage
        });
        setDeleteConfirmationOpen(false);
    };

    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);

    useEffect(() => {
        const selectedRowsData = rowSelectionModel.map((id) => rows.find((row) => row.id === id));
        const rowHasNoAccount = selectedRowsData?.find((row) => row.isOrganization && !row.hasAccount);

        if (selectedRowsData.length > 0 && !rowHasNoAccount) {
            setShowInviteButton(true);
        } else {
            setShowInviteButton(false);
        }
    }, [rowSelectionModel]);

    const isRowSelectable = React.useCallback(
        (params: GridRowParams) => {
            if (
                (!params.row?.isPerson && !params.row?.isOrganization) ||
                (params.row?.inquiryStatus && inquiryStatus[params.row?.inquiryStatus]?.disabled)
            )
                return false;
            if (rowSelectionModel.includes(params.id)) return true;
            return true;
        },
        [rowSelectionModel]
    );

    const inviteButton = (
        <Button
            key={'invite'}
            startIcon={<Icons.RequestAvailability />}
            size={'small'}
            disabled={!showInviteButton}
            onClick={() => {
                const selectedRowsData = rowSelectionModel.map((id) => rows.find((row) => row.id === id));
                const filterIds = [];
                setSelectedItems(
                    selectedRowsData.map((row) => {
                        filterIds.push(row.itemReference.id);
                        return { ...row.itemReference, shortlistLineItemId: row.identity.id };
                    })
                );
                setFilter([{ field: 'identity', value: { values: [...filterIds] } }]);
                setOpenInviteModal(true);
            }}
        >
            <I18n token="form.shortlistLineItem.iviteButton" />
        </Button>
    );

    const productionOptions = useMemo(
        () =>
            productionData?.otherTitles
                ? productionData.otherTitles
                      ?.filter((item) => item.value)
                      .map((title) => {
                          return { id: title?.value, label: title?.value, key: title?.key };
                      })
                : [],
        [productionData?.otherTitles]
    );

    return (
        <WithSidePanel
            visibleSidePanelId={entityReference ? 'notes' : undefined}
            panels={[
                {
                    id: 'notes',
                    icon: Icons.Notes,
                    label: 'Notes',
                    tooltip: entityReference ? 'Close notes' : 'Click on one of the note icons in the grid.',
                    disabled: !entityReference,
                    onClick: () => {
                        return new Promise<void>((resolve) => {
                            setEntityReference(undefined);
                            resolve();
                        });
                    },
                    component: (onClose) => (
                        <Notes
                            id={'notes-lab'}
                            title={panelTitle}
                            entityReference={entityReference}
                            onClose={() => {
                                setEntityReference(undefined);
                                onClose();
                            }}
                            featurePermissions={featurePermissions}
                            entityPermissions={entityPermissions}
                        />
                    )
                }
            ]}
            leftToolbar={undefined}
            hideHeader={true}
        >
            <V sx={{ height: '100%', minWidth: '1px', position: 'relative' }}>
                <DataGrid
                    id={'shortlist-lineitems'}
                    loading={isLineItemDeleting}
                    feature={'Shortlist'}
                    addTitle={<I18n token="shortlist.line.item.add" />}
                    rowCount={rows?.length || 0}
                    rows={rows ? rows : []}
                    columns={columns}
                    toolbar={{ add: true, custom: resourceData && productionData && canInvite ? [inviteButton] : [] }}
                    onAdd={() => {
                        setIsOpen(true);
                        setLineItemIndex(undefined);
                    }}
                    onEdit={(index) => {
                        setIsOpen(true);
                        setLineItemIndex(index);
                    }}
                    onDelete={(lineItemId: string) => {
                        setLineItemToDelete(lineItemId);
                        setDeleteConfirmationOpen(true);
                    }}
                    disablePagination
                    checkboxSelection={!!(resourceData && productionData && canInvite)}
                    disableRowSelectionOnClick
                    isRowSelectable={isRowSelectable}
                    rowSelectionModel={rowSelectionModel}
                    onRowSelectionModelChange={setRowSelectionModel}
                    asIsColumns={['icons']}
                />
            </V>
            {isOpen && (
                <ShortlistLineItemEditor
                    shortlistId={shortlistId}
                    isEditorOpen={true}
                    setIsEditorOpen={setIsOpen}
                    lineItem={rows?.[lineItemIndex]}
                    viewState={viewState}
                    resourceData={resourceData}
                    onSuccess={() => {
                        refetchShortlistData();
                        setIsOpen(false);
                    }}
                />
            )}
            <Confirm
                open={deleteConfirmationOpen}
                title={<I18n token="delete.confirm.title" />}
                okLabelI18n="delete.confirm.title"
                body={
                    <Typography>
                        <I18n token="delete.confirm.message" />
                    </Typography>
                }
                onConfirm={deleteLineItem}
                onCancel={() => {
                    setDeleteConfirmationOpen(false);
                }}
            />
            {openInviteModal && canInvite && isTypeCrew && (
                <PersonInviteForm
                    isOpen={openInviteModal}
                    onClose={() => {
                        setOpenInviteModal(false);
                    }}
                    onSuccess={(inquiriesInput) => {
                        setViewState((prevVal) => {
                            return inquiriesInputToShortlist(inquiriesInput, prevVal);
                        });

                        setRowSelectionModel([]);
                        setOpenInviteModal(false);
                    }}
                    productionData={productionData}
                    data={{
                        people: selectedItems,
                        resource: { label: resourceData?.name, ...resourceData?.reference },
                        resourceCategory: resourceData?.resourceCategory,
                        production: {
                            reference: productionData?.reference,
                            name: productionData?.name,
                            headline: '',
                            productionType: productionData?.productionType
                        },
                        productionOptions: [
                            ...productionOptions,
                            ...(productionData
                                ? [
                                      {
                                          id: productionData.name,
                                          label: productionData.name
                                      }
                                  ]
                                : [])
                        ],
                        role: viewState?.roles?.[0],
                        locations: resourceData?.locations,
                        dateRange: resourceData?.resourceSchedule.dateRange || { ...EMPTY_DATERANGE.dateRange },
                        personalizedMessage: personalizedMessage,
                        synopsis: productionData?.about || ''
                    }}
                    filter={filter}
                />
            )}
            {openInviteModal && canInvite && !isTypeCrew && selectedItems.length && (
                <OrganizationInviteForm
                    isOpen={openInviteModal}
                    data={{
                        organizations: selectedItems,
                        resource: { label: resourceData?.name, ...resourceData?.reference },
                        resourceCategory: resourceData?.resourceCategory,
                        locations: resourceData?.locations,
                        dateRange: resourceData?.resourceSchedule.dateRange || {
                            ...EMPTY_DATERANGE.dateRange
                        },
                        production: {
                            reference: productionData?.reference,
                            name: productionData?.name,
                            headline: '',
                            productionType: productionData?.productionType
                        },
                        productionOptions: [
                            ...productionOptions,
                            ...(productionData
                                ? [
                                      {
                                          id: productionData.name,
                                          label: productionData.name
                                      }
                                  ]
                                : [])
                        ],
                        activities: [],
                        productionSummary: productionData?.about,
                        personalizedMessage: personalizedMessageForOrgInquiry,
                        resourceNote: { ...resourceNote, text: '' }
                    }}
                    onSuccess={(inquiriesInput) => {
                        setViewState((prevVal) => {
                            return inquiriesInputToShortlist(inquiriesInput, prevVal);
                        });

                        setRowSelectionModel([]);
                        setOpenInviteModal(false);
                    }}
                    onClose={() => {
                        setOpenInviteModal(false);
                    }}
                    filter={filter}
                />
            )}
        </WithSidePanel>
    );
};
