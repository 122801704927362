import React, { useContext, useState } from 'react';
import FormPanel from '../../common/form/FormPanel';
import { FormContext, FormProvider } from '../../common/form/FormContext';
import { get } from 'lodash';
import { Contact } from '../../common/components/types';
import ContactList from '../opportunity/details/ContactList';
import { altColDefs, rowsToContacts } from '../opportunity/details/ContactListUtils';
import { ALL_PERMISSIONS } from '../../common/auth/api';
import { bulkAutoCreateQuery, client } from '../../common/components/person/person';
import { doSave } from '../opportunity/details/BulkSaveContacts';
import { useMutationWithContext } from '../../common/hooks/useMutationWithContext';
import SRDataImport from '../../common/SRDataImport/SRDataImport';
import { useImportPersonRowValidation } from '../../person/useImportPersonRowValidation';

const placeholder = `To enter data:
Simply type in this area, use Commas to separate guest attributes and each person on a new line
Copy paste from Excel or other documents
Or drop an Excel or CSV file here
Rearrange the headers above to match the order of the data you entered`;

export default ({
    colDefs,
    columnVisibility,
    setDisableSave = undefined,
    name = 'guests',
    minHeight = '600px',
    toolbar = {
        columns: true,
        add: true,
        export: true,
        custom: true
    }
}) => {
    const { state, setState, handleChange } = useContext(FormContext);
    const guests: Contact[] = get(state, name) || [];
    const [importVisible, setImportVisible] = useState(false);
    const [isUpload, setIsUpload] = useState(false);
    const AllPermissions = ALL_PERMISSIONS;
    const [bulkSave] = useMutationWithContext(bulkAutoCreateQuery, client);
    const personValidation = useImportPersonRowValidation();

    const renumberGuests = (guests) => {
        guests.forEach((guest, index) => {
            guest.id = index;
        });
        return guests;
    };

    return (
        <FormPanel>
            <FormProvider state={undefined}>
                <ContactList
                    addTitle={'Guests'}
                    contacts={guests}
                    colDefs={colDefs}
                    columnVisibility={columnVisibility}
                    feature="Opportunities"
                    exportFilename={''}
                    sx={{ pt: 0, minHeight: minHeight }}
                    setImportVisible={(isVisible, isUpload) => {
                        setImportVisible(isVisible);
                        setIsUpload(isUpload);
                    }}
                    onAddContact={(newContact: Contact) => {
                        const newGuests = renumberGuests([newContact, ...guests]);
                        handleChange(name, newGuests);
                    }}
                    onEditContact={(updatedContact: Contact) => {
                        const newGuests = renumberGuests(guests);
                        const updatedNewGuests = newGuests.map((guest) =>
                            guest.id === updatedContact.id ? updatedContact : guest
                        );
                        handleChange(name, updatedNewGuests);
                    }}
                    onDeleteContact={(deletedContact: Contact) => {
                        const newGuests = renumberGuests(guests);
                        const filteredGuests = newGuests.filter((guest) => guest.id !== deletedContact.id);
                        handleChange(name, filteredGuests);
                    }}
                    featurePermissions={AllPermissions}
                    entityPermissions={AllPermissions}
                    onError={(error: Boolean) => setDisableSave && setDisableSave(error)}
                    toolbar={toolbar}
                />
                {importVisible ? (
                    <SRDataImport
                        feature={'Opportunities'}
                        primaryColDefs={colDefs}
                        altColDefs={altColDefs}
                        altColDefLabel={'First name and last name are in the same field'}
                        columnVisibility={columnVisibility}
                        rowsToEntities={rowsToContacts}
                        onSave={(newImportedGuests) =>
                            new Promise<void>((resolve, reject) => {
                                const newGuests = (state.guests || []).concat(newImportedGuests);
                                setState({ ...state, [name]: newGuests });
                                resolve();
                            })
                        }
                        isUpload={isUpload}
                        onClose={() => setImportVisible(false)}
                        doSave={doSave}
                        bulkSave={bulkSave}
                        placeholder={placeholder}
                        disableBulkSave
                        validateImportedData={personValidation}
                        existingData={guests}
                    />
                ) : (
                    <React.Fragment />
                )}
            </FormProvider>
        </FormPanel>
    );
};
