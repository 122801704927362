import * as React from 'react';
import { useContext, useState } from 'react';
import List from '@mui/material/List';
import Loading from '../components/Loading';
import Divider from '@mui/material/Divider';
import { Chip, ListItemButton, SxProps, Typography } from '@mui/material';
import { ScrollableLayout, V } from '../Layout';
import Center from '../components/Center';
import I18n from '../i18n/I18n';
import Pagination, { PaginationConfig } from '../../supply/searchkit/pagination/Pagination';
import { useStyling } from '../Theme';
import { ActionType } from '../components/ActionType';
import { AllPermissions, getFeaturePermissions, getRowPermissions } from '../auth/api';
import { UserContext } from '../auth/UserContext';
import { Icons } from '../icons/Icons';
import ResponsiveActions from '../components/ResponsiveActions';

type DataListProps = {
    feature?: string;
    id?: string;
    loading?: boolean;
    items: any[];
    onClick: (item) => void;
    refetch?: () => void;
    pagination?: PaginationConfig;
    ItemRenderer: any;
    disableRipple?: boolean;
    divider?: boolean;
    border?: boolean;
    dense?: boolean;
    onNotes?: (ref, name) => void;
    onEdit?: (item) => void;
    onDelete?: (item) => void;
    showActions?: boolean;
    emptyListElement?: React.ReactNode;
    onActivateOrInactivateHandle?: (row, isInactivate, isActivate) => void;
    listItemSx?: SxProps;
};

export const getActions = (
    featurePermissions,
    rowPermissions,
    item: any,
    onNotes?: (ref, name) => void,
    onEdit?: (item) => void,
    onDelete?: (item) => void,
    onActivateOrInactivateHandle = undefined
): ActionType[] => {
    const actions: ActionType[] = [];

    if (onNotes && featurePermissions[AllPermissions.Manage]) {
        actions.push({
            icon: Icons.Notes,
            onClick: (event) => {
                event.stopPropagation();
                onNotes(item.reference, item.name);
            }
        });
    }

    if (onEdit && rowPermissions?.[AllPermissions.Edit] && featurePermissions?.[AllPermissions.Manage]) {
        actions.push({
            icon: Icons.EditOutlined,
            onClick: (event) => {
                event.stopPropagation();
                onEdit(item.id);
            }
        });
    }

    if (onDelete && rowPermissions[AllPermissions.Delete] && featurePermissions[AllPermissions.Manage]) {
        actions.push({
            icon: Icons.Delete,
            onClick: (event) => {
                event.stopPropagation();
                onDelete(item.id);
            }
        });
    }

    if (
        (onActivateOrInactivateHandle && rowPermissions[AllPermissions.Inactivate]) ||
        (onActivateOrInactivateHandle && rowPermissions[AllPermissions.Activate])
    ) {
        const isInactivate = rowPermissions[AllPermissions.Inactivate];
        const isActivate = rowPermissions[AllPermissions.Activate];
        const title =
            (isInactivate && <I18n token={'action.inactive'} />) || (isActivate && <I18n token={'action.active'} />);
        actions.push({
            label: title,
            onClick: (e) => {
                e.stopPropagation();
                onActivateOrInactivateHandle(item, isInactivate, isActivate);
            }
        });
    }

    return actions;
};

const DataListTile = ({ item, ItemRenderer, actions, onClick, disableRipple, listItemSx }) => {
    const { theme, isDarkMode } = useStyling();
    const [isHover, setIsHover] = useState(false);

    return (
        <ListItemButton
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            sx={{
                pl: 1,
                pr: 1,
                position: 'relative',
                alignItems: 'flex-start',
                '&:hover': {
                    backgroundColor: theme.palette.grey[isDarkMode ? 700 : 100],
                    cursor: 'pointer'
                },
                ...listItemSx
            }}
            onClick={() => onClick(item)}
            disableRipple={disableRipple}
        >
            <ItemRenderer item={item} />
            {!!actions.length && <ResponsiveActions showBorder={true} isActivated={isHover} actions={actions} />}
        </ListItemButton>
    );
};

export default (props: DataListProps) => {
    const {
        id,
        loading = false,
        items,
        refetch,
        pagination,
        onClick,
        ItemRenderer,
        onActivateOrInactivateHandle = undefined,
        disableRipple = false,
        divider = false,
        border = false,
        dense = false,
        feature,
        onNotes,
        onEdit,
        onDelete,
        showActions = true,
        emptyListElement = undefined,
        listItemSx = {}
    } = props;
    const { userProfile, activeOrganizationAccount, application } = useContext(UserContext);
    const featurePermissions = getFeaturePermissions(userProfile, application, activeOrganizationAccount, feature);
    const { theme } = useStyling();

    return (
        <V
            sx={{
                backgroundColor: theme.palette.background.paper,
                height: '100%',
                overflow: 'hidden',
                border: border ? `1px solid ${theme.palette.divider}` : 'none',
                borderRadius: 1
            }}
        >
            <ScrollableLayout sx={{ pt: 0, pb: 0, pr: 1 }}>
                <List id={`${id}-list`} sx={{ height: '100%', width: '100%', overflowY: 'auto' }} dense={dense}>
                    {loading ? (
                        <Loading />
                    ) : items && items.length ? (
                        items.map((item, index) => {
                            const rowPermissions = getRowPermissions(item?.security);
                            const actions = getActions(
                                featurePermissions,
                                rowPermissions,
                                item,
                                onNotes,
                                onEdit,
                                onDelete,
                                onActivateOrInactivateHandle
                            );
                            return (
                                <React.Fragment key={index}>
                                    <DataListTile
                                        item={item}
                                        ItemRenderer={ItemRenderer}
                                        actions={actions}
                                        onClick={onClick}
                                        disableRipple={disableRipple}
                                        listItemSx={listItemSx}
                                    />
                                    {divider && index < items.length - 1 && <Divider />}
                                </React.Fragment>
                            );
                        })
                    ) : (
                        <Center>
                            {emptyListElement || (
                                <Typography>
                                    <I18n token={'error.listEmpty'} />
                                </Typography>
                            )}
                        </Center>
                    )}
                </List>
            </ScrollableLayout>
            {pagination && <Pagination id={id} pagination={pagination} />}
        </V>
    );
};
