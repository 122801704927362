import React, { useContext, useEffect, useMemo, useState } from 'react';
import FormPanel from '../../common/form/FormPanel';
import { FormContainer } from '../../common/form/FormContainer';
import FormWidget from '../../common/form/FormWidget';
import FormItem from '../../common/form/FormItem';
import ScheduleWidget, { EMPTY_SCHEDULE } from '../../common/form/widgets/ScheduleWidget';
import I18n, { useI18n } from '../../common/i18n/I18n';
import { FormContext } from '../../common/form/FormContext';
import { isEmpty, isEqual } from 'lodash';
import { getActivityOptions } from '../../production/resource/ResourceAssociatedActivityList';
import AssociateActivityOptionRenderer from '../../production/resource/AssociateActivityOptionRenderer';
import ProductionTitleForm from './ProductionTitleForm';

export default ({ data }) => {
    const { state, setState, handleChange } = useContext(FormContext);

    const [isEditing, setIsEditing] = useState(!data.resourceNote);
    const [isOpen, toggleOpen] = useState(false);
    const [savedValue, setSavedValue] = useState(data.production.name);
    const [selectedValue, setSelectedValue] = useState('');
    const [productionOptions, setProductionOptions] = useState([...(data?.productionOptions || [])]);

    const isDisabled = useMemo(() => !isEqual(data?.dateRange, EMPTY_SCHEDULE), [data]);
    const defaultProductionName = data?.production?.name;

    useEffect(() => {
        if (data?.resourceNote && isEmpty(data.resourceNote?.text)) {
            setIsEditing(true);
        }
    }, [data?.resourceNote]);

    useEffect(() => {
        handleChange('production.name', savedValue);
    }, [savedValue]);

    return (
        state && (
            <FormPanel>
                <FormItem>
                    <FormWidget
                        name="organizations"
                        label={<I18n token="organization.invite.form.label.organization" />}
                        component="ReferenceAutocomplete"
                        entity={'Organization'}
                        data-testid={'organization'}
                        multiple
                        disabled
                        preload={false}
                    />
                </FormItem>
                <FormContainer>
                    <FormItem>
                        <FormWidget
                            name="resource"
                            label={<I18n token="organization.invite.form.label.resource" />}
                            component="ReferenceAutocomplete"
                            entity={'Resource'}
                            data-testid={'resource'}
                            disabled
                            preload={false}
                        />
                    </FormItem>
                </FormContainer>
                <FormContainer>
                    <FormItem half>
                        <FormWidget
                            component="MetadataAutocomplete"
                            metadataName="Category"
                            name="resourceCategory"
                            label={<I18n token="organization.invite.form.label.resourceCategory" />}
                            data-testid={'resource-category'}
                            disabled
                        />
                    </FormItem>
                    <FormItem half>
                        <FormWidget
                            name="locations"
                            component="Location"
                            label={<I18n token="organization.invite.form.label.locations" />}
                            multiple
                            data-testid={'location'}
                            disabled={Boolean(data?.locations?.length)}
                        />
                    </FormItem>
                </FormContainer>
                <FormContainer title={<I18n token="organization.invite.form.label.resource.notes" />}>
                    <FormItem>
                        <FormWidget
                            name="resourceNote.content"
                            label={<I18n token="organization.invite.form.label.resource.notes" />}
                            component="RichText"
                            contentPlaceholder={<I18n token="resource.notes.form.content.placeholder" />}
                            onSave={undefined}
                            onCancel={undefined}
                            readOnly={!isEditing}
                            disabled={data?.resourceNotes}
                            getTextFromContent={(text: string) => handleChange('resourceNote.text', text)}
                        />
                    </FormItem>
                </FormContainer>
                <FormContainer>
                    <FormItem>
                        <FormWidget
                            hideHelperText
                            component={'Switch'}
                            name="dateRange.durationSpecified"
                            label={<I18n token="form.shortlistLineItem.inquiry.durationSpecified" />}
                            disabled={isDisabled}
                        />
                    </FormItem>
                    <ScheduleWidget
                        disabled={isDisabled}
                        showFullSchedule={true}
                        name="dateRange"
                        allowedUnits={['hour', 'day', 'week']}
                    />
                </FormContainer>
                <FormContainer>
                    <FormItem style={{ display: 'flex', flexDirection: 'column' }}>
                        <FormWidget
                            name="production.name"
                            label={useI18n('organization.invite.form.label.production')}
                            component="Autocomplete"
                            options={productionOptions}
                            data-testid={'production'}
                            editInputValue
                            value={savedValue}
                            onChange={(name, value) => {
                                setSavedValue(value);
                            }}
                            selectOnFocus
                            allowFreeForm
                            setSelectedValue={setSelectedValue}
                            renderOption
                            isAddEnabled
                            isCustomEelementOpen={isOpen}
                            toggleOpen={() => {
                                toggleOpen(true);
                            }}
                            disabled={isOpen}
                            onBlur={() => {
                                if (!savedValue && !state?.production.name) {
                                    setSavedValue(defaultProductionName);
                                }
                            }}
                        />
                        {isOpen && (
                            <ProductionTitleForm
                                setSelectedValue={setSelectedValue}
                                setSavedValue={setSavedValue}
                                selectedValue={selectedValue}
                                setState={setState}
                                toggleOpen={toggleOpen}
                                setProductionOptions={setProductionOptions}
                                productionOptions={productionOptions}
                            />
                        )}
                    </FormItem>
                </FormContainer>
                <FormContainer>
                    <FormItem>
                        <FormWidget
                            name="productionSummary"
                            component={'TextArea'}
                            label={<I18n token="organization.invite.form.label.production.summary" />}
                            minRows={3}
                            disabled={!isEmpty(data.productionSummary)}
                        />
                    </FormItem>
                </FormContainer>
                <FormContainer>
                    <FormItem>
                        <FormWidget
                            multiple
                            name="activities"
                            component="ReferenceAutocomplete"
                            entity="Activity"
                            label={<I18n token="organization.invite.form.label.production.schedule" />}
                            filter={{
                                expressions: [
                                    { field: 'production', value: { values: [data?.production?.reference.id] } }
                                ]
                            }}
                            disableIfOptionUnavailable
                            getOption={getActivityOptions}
                            OptionRenderer={AssociateActivityOptionRenderer}
                            preload={false}
                        />
                    </FormItem>
                </FormContainer>
                <FormContainer>
                    <FormItem>
                        <FormWidget
                            name="personalizedMessage"
                            component={'TextArea'}
                            label={<I18n token="organization.invite.form.label.message" />}
                            minRows={3}
                        />
                    </FormItem>
                </FormContainer>
            </FormPanel>
        )
    );
};
