import { alpha, Components, createTheme, darken, lighten, ThemeOptions } from '@mui/material';
import { blue, cyan, green, grey, orange, pink, red, yellow } from '@mui/material/colors';
import { Theme } from '@mui/material/styles';
import { PaletteColorOptions, PaletteOptions, SimplePaletteColorOptions } from '@mui/material/styles/createPalette';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { SxProps } from '@mui/system';
import { DataGridPremiumComponents } from '@mui/x-data-grid-premium/themeAugmentation';
import React from 'react';
import { useRecoilState } from 'recoil';
import { BreakpointSize, useOnMobile } from './hooks/breakpoints';
import { useCurrentBreakpoint } from './hooks/useCurrentBreakpoint';
import {
    COLOR_EDITING_DARK,
    COLOR_EDITING_LIGHT,
    COLOR_ERROR_DARK,
    COLOR_ERROR_LIGHT,
    COLOR_PRIMARY_DARK,
    COLOR_PRIMARY_LIGHT,
    COLOR_SUCCESS_DARK,
    COLOR_SUCCESS_LIGHT,
    COLOR_WARNING_DARK,
    COLOR_WARNING_LIGHT
} from './Colors';
import { getL10nDef } from './i18n/localization';
import { isDarkModeAtom } from './utils/DarkModeAtom';

const typography: TypographyOptions = {
    fontFamily: 'Lato, "Helvetica Neue", Arial, Helvetica, sans-serif'
};

declare module '@mui/material/styles' {
    export interface Theme {
        status: {
            danger: React.CSSProperties['color'];
        };
    }
    export interface ThemeOptions {
        status: {
            danger: React.CSSProperties['color'];
        };
    }
    export interface Palette {
        active?: Palette['primary'];
        inactive?: Palette['primary'];
        selected?: SimplePaletteColorOptions;
        required?: SimplePaletteColorOptions;
    }
    export interface PaletteOptions {
        active?: PaletteOptions['primary'];
        inactive?: PaletteOptions['primary'];
        selected?: SimplePaletteColorOptions;
        required?: SimplePaletteColorOptions;
    }

    export interface PaletteColor {
        darker?: string;
    }

    export interface SimplePaletteColorOptions {
        darker?: string;
    }
}

declare module '@mui/material/ButtonGroup' {
    export interface ButtonGroupPropsColorOverrides {
        active: true;
        inactive: true;
    }
}

declare module '@mui/material/Button' {
    export interface ButtonPropsColorOverrides {
        active: true;
        inactive: true;
    }
}

export const stringToColor = (str) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < str.length; i += 1) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
};

const getComponentOverrides = (
    isDark: boolean,
    palette: CustomPaletteOptions
): Components | DataGridPremiumComponents => {
    return {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundImage: 'none',
                    minWidth: '1px'
                }
            },
            defaultProps: {
                elevation: 5
            }
        },
        MuiAppBar: {
            defaultProps: {
                elevation: 5,
                color: 'default'
            }
        },
        MuiDrawer: {
            defaultProps: {
                elevation: 5
            }
        },
        MuiGrid: {
            styleOverrides: {
                item: {
                    // paddingTop: '8px !important'
                },
                root: {
                    // paddingLeft: '8px !important'
                }
            }
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                groupedHorizontal: {
                    height: '32px',
                    minHeight: '32px'
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    '&.Mui-error': {
                        color: (palette.error as SimplePaletteColorOptions).main
                    },
                    '&.Mui-required': {
                        color: (palette.required as SimplePaletteColorOptions).main
                    }
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    '&.Mui-error': {
                        color: (palette.error as SimplePaletteColorOptions).main
                    },
                    '&.Mui-required': {
                        color: (palette.required as SimplePaletteColorOptions).main
                    }
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '&.Mui-error': {
                        color: (palette.error as SimplePaletteColorOptions).main
                    },
                    '&.Mui-required': {
                        color: (palette.required as SimplePaletteColorOptions).main
                    },
                    '&.MuiFormHelperText': {
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis'
                    }
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    lineHeight: 1
                }
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    '&.MuiChip-sizeSmall': {
                        height: '20px'
                    }
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor: isDark ? alpha(grey.A700, 0.7) : alpha(grey.A400, 0.7)
                    },
                    '&.Mui-selected': {
                        backgroundColor: (palette.selected as SimplePaletteColorOptions).main,
                        color: (palette.selected as SimplePaletteColorOptions).contrastText
                    },
                    '&.Mui-selected:hover': {
                        backgroundColor: alpha((palette.selected as SimplePaletteColorOptions).main, 0.9),
                        color: (palette.selected as SimplePaletteColorOptions).contrastText
                    }
                }
            }
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    '& .MuiDataGrid-row': {
                        '.MuiDataGrid-cell': {
                            backgroundColor: 'unset'
                        },
                        '.MuiDataGrid-cell--textBold': {
                            '.MuiTypography-root, input': {
                                fontWeight: 'bold'
                            }
                        },
                        '.MuiDataGrid-cell--textRight': {
                            '.MuiTypography-root, .MuiInputBase-input': {
                                width: '100%',
                                textAlign: 'right',
                                paddingRight: '1px'
                            }
                        },
                        // row being edited
                        '&.MuiDataGrid-row--editing': {
                            backgroundColor: isDark ? COLOR_EDITING_DARK : COLOR_EDITING_LIGHT,
                            color: isDark ? 'white' : 'black',
                            boxShadow: 'none',

                            '&:hover, &.Mui-hovered': {
                                backgroundColor: isDark ? COLOR_EDITING_DARK : COLOR_EDITING_LIGHT
                            },
                            '.MuiDataGrid-cell--editing': {
                                '.MuiAutocomplete-root': {
                                    fieldset: {
                                        border: 'none'
                                    }
                                },
                                '&.isEmpty': {
                                    background: `linear-gradient(to top right,transparent 50%,${
                                        (palette.error as SimplePaletteColorOptions).main
                                    } 50.1%) top right/10px 10px no-repeat,transparent;`
                                }
                            }
                        },
                        // rows not being edited
                        '&:not(.MuiDataGrid-row--editing)': {
                            '&:hover, &.Mui-hovered': {
                                background: 'transparent'
                            }
                        }
                    }
                }
            }
        }
    };
};

interface CustomPaletteOptions extends PaletteOptions {
    active?: SimplePaletteColorOptions;
    required?: SimplePaletteColorOptions;
    selected?: SimplePaletteColorOptions;
    inactive?: SimplePaletteColorOptions;
}

const getDarkPalette = (): CustomPaletteOptions => {
    return {
        mode: 'dark',
        primary: {
            main: COLOR_PRIMARY_DARK,
            light: lighten(COLOR_PRIMARY_DARK, 0.2),
            dark: darken(COLOR_PRIMARY_DARK, 0.2)
        },
        active: {
            main: blue.A100,
            contrastText: 'rgba(255, 255, 255, 1)'
        },
        required: {
            main: lighten(COLOR_PRIMARY_DARK, 0.2)
        },
        selected: {
            main: grey.A700,
            contrastText: 'rgba(255, 255, 255, 1)'
        },
        inactive: {
            main: grey.A400,
            contrastText: grey.A700
        },
        text: {
            primary: grey[50],
            secondary: 'rgba(255, 255, 255, 0.7)',
            disabled: grey[600]
        },
        background: {
            default: grey[900],
            paper: grey[800]
        },
        success: {
            main: COLOR_SUCCESS_DARK
        },
        error: {
            main: COLOR_ERROR_DARK,
            light: lighten(COLOR_ERROR_DARK, 0.2),
            dark: darken(COLOR_ERROR_DARK, 0.2)
        },
        warning: {
            main: COLOR_WARNING_DARK
        }
    };
};

const getLightPalette = (): CustomPaletteOptions => {
    return {
        mode: 'light',
        primary: {
            main: COLOR_PRIMARY_LIGHT,
            light: lighten(COLOR_PRIMARY_LIGHT, 0.2),
            dark: darken(COLOR_PRIMARY_LIGHT, 0.2)
        },
        secondary: {
            main: 'rgba(236, 99, 55, 1)'
        },
        required: {
            main: lighten(COLOR_PRIMARY_LIGHT, 0.2)
        },
        selected: {
            main: grey.A400,
            contrastText: 'rgba(0, 0, 0, 1)'
        },
        active: {
            main: 'rgba(225, 241, 249, 1)',
            contrastText: 'rgba(2, 136, 209, 1)'
        },
        inactive: {
            main: grey.A700,
            contrastText: grey.A400
        },
        text: {
            primary: grey[900],
            secondary: 'rgba(0, 0, 0, 0.7)',
            disabled: grey[500]
        },
        background: {
            default: 'rgba(231, 231, 231, 1)',
            paper: 'rgba(255, 255, 255, 1)'
        },
        success: {
            main: COLOR_SUCCESS_LIGHT
        },
        error: {
            main: COLOR_ERROR_LIGHT,
            light: lighten(COLOR_ERROR_LIGHT, 0.2),
            dark: darken(COLOR_ERROR_LIGHT, 0.2)
        },
        warning: {
            main: COLOR_WARNING_LIGHT
        }
    };
};

const getShape = () => {
    return {
        borderRadius: 10
    };
};

const getTheme = (isDark): Theme => {
    const darkPalette = getDarkPalette();
    const lightPalette = getLightPalette();
    const shape = getShape();
    const l10n = getL10nDef();
    const translations = [l10n.muiCore, l10n.muiPickers, l10n.muiGrid];
    return isDark
        ? createTheme(
              {
                  components: getComponentOverrides(isDark, darkPalette),
                  status: {
                      danger: red.A400
                  },
                  palette: darkPalette,
                  typography: typography,
                  shape: shape
              } as ThemeOptions,
              ...translations
          )
        : createTheme(
              {
                  components: getComponentOverrides(isDark, lightPalette),
                  status: {
                      danger: '#C13F38'
                  },
                  palette: lightPalette,
                  typography: typography,
                  shape: shape
              } as ThemeOptions,
              ...translations
          );
};

export const useStyling = () => {
    const isMobile = useOnMobile();
    const [isDarkMode, setIsDarkMode] = useRecoilState(isDarkModeAtom);
    const breakpoint = useCurrentBreakpoint();

    const theme = React.useMemo(() => {
        return getTheme(isDarkMode);
    }, [isDarkMode]);

    const timelineColorsByStatus = {
        Unavailable: grey['400'],
        Booked: green['400'],
        Hold: cyan['400'], // Obsolete
        '1st Hold': blue['400'],
        '2nd Hold': orange['400'],
        Inquiry: yellow['600'],
        Lead: pink['400'],
        'Closed Lost': '#A9B6FB',
        Quoted: '#FECEA8'
    };

    const attributeColorsByResourceStatus = React.useMemo(() => {
        //TO DO: change colors once we get response from designer
        return isDarkMode
            ? {
                  Open: grey['200'],
                  Consideration: cyan['200'],
                  'Request for Proposal': blue['200'],
                  'Proposal Review': orange['200'],
                  Negotiating: yellow['400'],
                  Booked: green['200'],
                  Closed: red['200']
              }
            : {
                  Open: grey['400'],
                  Consideration: cyan['400'],
                  'Request for Proposal': blue['400'],
                  'Proposal Review': orange['400'],
                  Negotiating: yellow['600'],
                  Booked: green['400'],
                  Closed: red['400']
              };
    }, [isDarkMode]);

    return {
        theme,
        isDarkMode,
        setIsDarkMode,
        isMobile,
        timelineColorsByStatus,
        attributeColorsByResourceStatus
    };
};
