import {
    ApolloClient,
    createHttpLink,
    DocumentNode,
    gql,
    InMemoryCache,
    TypedDocumentNode,
    useMutation,
    useQuery
} from '@apollo/client';
import { ReferenceFragment } from '../../../common/list/fragments';
import { authLink } from '../../../common/auth/api';
import { Order, Workflow, WorkflowStepGroup, Contact, Note } from 'sr-types/lib/order/v1/graphql';
import { useContext } from 'react';
import { UserContext } from '../../../common/auth/UserContext';
import { constants } from '../../../common/constants';
import { AddressFragment } from '../../../common/components/location';
import { useI18n } from '../../../common/i18n/I18n';
import { AttachmentInput } from 'sr-types/lib/production/v1/graphql';
import { enqueueSnackbar } from '../../../common/components/Toast';
import { NoteInput } from 'sr-types/lib/note/v1/graphql';
import {
    acceptedFilesMock,
    documentExtensions,
    spreadsheetExtensions
} from '../../../common/attachments/attachmentsApi';
import { cloneDeep, isNil } from 'lodash';

export const orderClient = new ApolloClient({
    link: authLink.concat(
        createHttpLink({
            uri: '/order/v1/'
        })
    ),
    cache: new InMemoryCache({
        addTypename: false
    })
});

export type OrderType = {
    order: Order;
    workflow: Workflow & { requestApproval: boolean };
    note: Note;
    attachments: AttachmentInput[];
};

export enum OrderStatus {
    approved = 'Approved',
    draft = 'Draft',
    pending = 'Approval Pending',
    cancelled = 'Cancelled',
    notOrdered = 'Not Ordered',
    quote = 'Quote',
    rejected = 'Rejected',
    revised = 'Revised',
    quoted = 'Quoted'
}

export enum OrderState {
    order = 'Order',
    quote = 'Quote'
}

export const acceptedAttachmentTypes = [...acceptedFilesMock, ...documentExtensions, ...spreadsheetExtensions];

export const orderStatusColors = {
    Approved: 'success',
    'Approval Pending': 'warning',
    Draft: 'default',
    Cancelled: 'secondary'
};

export enum Roles {
    approver = 'Approver',
    signer = 'Signer',
    carbonCopy = 'CarbonCopy'
}

export const getIndexOfRoles = (workflow: Workflow, role: string) => {
    const index = workflow.workflowStepGroups.findIndex((workflowStepGroup) => workflowStepGroup?.role === role);
    return index;
};

export const isRequestApprovalEnabled = (workflow: Workflow): boolean => {
    const approverIndex = getIndexOfRoles(workflow, Roles.approver);
    return Boolean(
        workflow?.workflowStepGroups[approverIndex]?.workflowSteps[0]?.workflowParticipant?.contacts?.length
    );
};

export const orderToState = (order: Order) => {
    const { production, orderOrganization, salesOrganization } = order;
    let updatedOrderOrganization = {};
    //If orderOrganization is available set includeOrderOrganization switch = true
    if (orderOrganization?.name) {
        const orderOrgLocation = { address: orderOrganization?.address, label: orderOrganization?.address?.label };
        updatedOrderOrganization = {
            ...orderOrganization,
            includeOrderOrganization: true,
            contact: orderOrganization?.contact ? [orderOrganization.contact] : [],
            location: orderOrgLocation,
            address: undefined
        };
    }
    //If production is available set includeProduction switch = true
    let updatedProduction = {};
    if (production?.name) {
        const productionLocation = { address: production?.address, label: production?.address?.label };
        updatedProduction = {
            ...production,
            includeProduction: true,
            contact: production?.contact ? [production.contact] : [],
            location: productionLocation,
            address: undefined
        };
    }

    const salesOrgLocation = { address: salesOrganization?.address, label: salesOrganization?.address?.label };
    let updatedSalesOrganization = {
        ...salesOrganization,
        contact: salesOrganization?.contact ? [salesOrganization.contact] : [],
        location: salesOrgLocation,
        address: undefined
    };
    const updatedOrder = {
        ...order,
        orderOrganization: updatedOrderOrganization,
        production: updatedProduction,
        salesOrganization: updatedSalesOrganization
    };
    return updatedOrder;
};

const assignEmptyWorkflowSteps = (workflow: Workflow, role: string, workflowStepGroups: WorkflowStepGroup[]) => {
    const index = workflow?.workflowStepGroups.findIndex((workflowStepGroup) => workflowStepGroup?.role === role);
    if (index === -1) {
        workflow.workflowStepGroups[workflow?.workflowStepGroups?.length] = workflowStepGroups.find(
            (workflowStepGroup) => workflowStepGroup?.role === role
        );
    }
};

export const workflowToState = (order: Order, workflow: Workflow) => {
    let updatedWorkflow = {};
    if (isNil(workflow)) {
        // Assign empty workflowStepGroups
        updatedWorkflow['workflowStepGroups'] =
            order.state === OrderState.order
                ? cloneDeep(EMPTY_WORKFLOWSTEPGROUPS)
                : cloneDeep(EMPTY_QOUTE_WORKFLOWSTEPGROUPS);
    } else {
        // Only assign empty workflowStepGroup for the role not available
        updatedWorkflow = { ...cloneDeep(workflow) };
        if (order.state === OrderState.order) {
            const rolesInOrder = [...Object.values(Roles)];
            rolesInOrder.forEach((role) => assignEmptyWorkflowSteps(updatedWorkflow, role, EMPTY_WORKFLOWSTEPGROUPS));
        } else {
            const rolesInQuote = [Roles.approver, Roles.carbonCopy];
            rolesInQuote.forEach((role) =>
                assignEmptyWorkflowSteps(updatedWorkflow, role, EMPTY_QOUTE_WORKFLOWSTEPGROUPS)
            );
        }
    }
    // Enable request toggle when approver is available
    updatedWorkflow['requestApproval'] = isRequestApprovalEnabled(updatedWorkflow);
    return updatedWorkflow;
};

export const stateToOrder = (order): Order => {
    const updatedOrder = { ...cloneDeep(order) };
    if (updatedOrder.production?.location && updatedOrder.production.location.address) {
        updatedOrder.production.address = updatedOrder.production.location.address;
    }
    if (updatedOrder.production?.productionReference) {
        updatedOrder.production.name = updatedOrder.production.productionReference.label;
    }
    if (updatedOrder.orderOrganization?.location && updatedOrder.orderOrganization.location.address) {
        updatedOrder.orderOrganization.address = updatedOrder.orderOrganization.location.address;
    }
    if (updatedOrder.orderOrganization?.contact) {
        updatedOrder.orderOrganization.contact = updatedOrder.orderOrganization.contact[0];
    }
    if (updatedOrder.orderOrganization?.organizationReference) {
        updatedOrder.orderOrganization.name = updatedOrder.orderOrganization.organizationReference.label;
    }
    if (updatedOrder.production?.contact) {
        updatedOrder.production.contact = updatedOrder.production.contact[0];
    }
    if (updatedOrder.salesOrganization?.organizationReference) {
        updatedOrder.salesOrganization.name = updatedOrder.salesOrganization.organizationReference.label;
    }
    if (updatedOrder.salesOrganization?.contact) {
        updatedOrder.salesOrganization.contact = updatedOrder.salesOrganization.contact[0];
    }
    if (!updatedOrder.production.includeProduction) {
        updatedOrder.production.productionReference = null;
        updatedOrder.production.contact = {};
        updatedOrder.production.address = {};
    }
    if (!updatedOrder.orderOrganization.includeOrderOrganization) {
        updatedOrder.orderOrganization.organizationReference = null;
        updatedOrder.orderOrganization.contact = {};
        updatedOrder.orderOrganization.address = {};
    }
    if (updatedOrder.salesOrganization?.location && updatedOrder.salesOrganization.location.address) {
        updatedOrder.salesOrganization.address = updatedOrder.salesOrganization.location.address;
    }
    delete updatedOrder.orderOrganization.location;
    delete updatedOrder.production.location;
    delete updatedOrder.orderOrganization.includeOrderOrganization;
    delete updatedOrder.production.includeProduction;
    delete updatedOrder.salesOrganization.location;
    delete updatedOrder.reference;
    delete updatedOrder.internalNote;
    delete updatedOrder.production?.address?.label;
    delete updatedOrder.orderOrganization?.address?.label;
    delete updatedOrder.salesOrganization?.address?.label;
    delete updatedOrder.isQuote;
    return updatedOrder;
};

const removeWorkflowStepGroup = (updatedWorkflow: Workflow, role: string) => {
    const roleIndex = getIndexOfRoles(updatedWorkflow, role);
    roleIndex > -1 && updatedWorkflow.workflowStepGroups.splice(roleIndex, 1);
};

export const stateToWorkflow = (workflow): Workflow => {
    const updatedWorkflow = { ...cloneDeep(workflow) };
    if (updatedWorkflow?.requestApproval) {
        const approvers = updatedWorkflow.workflowStepGroups.find(
            (workflowStepGroup) => workflowStepGroup.role === Roles.approver
        )?.workflowSteps[0].workflowParticipant.contacts;

        approvers.forEach((approver) => delete approver.id);
    } else {
        // Delete workflowStepGroup for role Approver when contact not available
        removeWorkflowStepGroup(updatedWorkflow, Roles.approver);
    }

    const signers = updatedWorkflow.workflowStepGroups.find(
        (workflowStepGroup) => workflowStepGroup?.role === Roles.signer
    )?.workflowSteps[0].workflowParticipant.contacts;

    if (signers?.length) {
        signers.forEach((signer) => delete signer.id);
    } else {
        // Delete workflowStepGroup for role signer when contact not available
        removeWorkflowStepGroup(updatedWorkflow, Roles.signer);
    }

    const carbonCopies = updatedWorkflow.workflowStepGroups.find(
        (workflowStepGroup) => workflowStepGroup?.role === Roles.carbonCopy
    )?.workflowSteps[0].workflowParticipant.contacts;

    if (carbonCopies?.length) {
        carbonCopies?.forEach((notify) => delete notify.id);
    } else {
        // Delete workflowStepGroup for role carboncopy when contact not available
        removeWorkflowStepGroup(updatedWorkflow, Roles.carbonCopy);
    }

    delete updatedWorkflow?.requestApproval;

    return updatedWorkflow;
};

export const stateToNote = (values): NoteInput => {
    if (values.note && !values.note.entityReference) {
        return values.order?.internalNote;
    }
    return values.note;
};

export const EMPTY_WORKFLOWSTEPGROUPS: WorkflowStepGroup[] = [
    {
        role: 'Approver',
        isSequential: false,
        workflowSteps: [
            {
                workflowParticipant: {
                    contacts: []
                }
            }
        ]
    },
    {
        role: 'Signer',
        isSequential: false,
        workflowSteps: [
            {
                workflowParticipant: {
                    contacts: []
                }
            }
        ]
    },
    {
        role: 'CarbonCopy',
        workflowSteps: [
            {
                workflowParticipant: {
                    contacts: []
                }
            }
        ]
    }
];

export const EMPTY_QOUTE_WORKFLOWSTEPGROUPS: WorkflowStepGroup[] = [
    {
        role: 'Approver',
        isSequential: false,
        workflowSteps: [
            {
                workflowParticipant: {
                    contacts: []
                }
            }
        ]
    },
    {
        role: 'CarbonCopy',
        provider: '',
        messageContent: '',
        workflowSteps: [
            {
                workflowParticipant: {
                    contacts: []
                }
            }
        ]
    }
];

export const ContactFragment: TypedDocumentNode<Contact> = gql`
    fragment Contact on Contact {
        name {
            firstName
            lastName
            salutation
            suffix
        }
        isPrimary
        contactInfo {
            email {
                address
                typeString
                verified
            }
            phone {
                number
                typeString
            }
        }
        personReference {
            ...Reference
        }
        role
        organization
        organizationReference {
            ...Reference
        }
        label
        production
        productionRole
        title
        organizationDepartment
        productionDepartment
    }
    ${ReferenceFragment}
`;

const OrderDetailsFragment: TypedDocumentNode<Order> = gql`
    fragment OrderDetails on Order {
        identity {
            id
            preSave
        }
        name
        status
        state
        orderNumber
        orderVersion
        opportunityReference {
            ...Reference
        }
        attachments {
            ...Reference
        }
        internalNote {
            identity {
                id
            }
            title
            content
            entityReference {
                ...Reference
            }
        }
        isQuote
        orderOrganization {
            name
            organizationReference {
                ...Reference
            }
            address {
                ...AddressFields
                label
            }
            contact {
                ...Contact
            }
        }
        salesOrganization {
            name
            organizationReference {
                ...Reference
            }
            address {
                ...AddressFields
                label
            }
            contact {
                ...Contact
            }
        }
        production {
            name
            productionReference {
                ...Reference
            }
            address {
                ...AddressFields
                label
            }
            contact {
                ...Contact
            }
        }
        reference {
            ...Reference
        }
        isExternalOMS
        customerPO
        terms
    }
    ${ReferenceFragment}
    ${AddressFragment}
    ${ContactFragment}
`;

export const WorkflowFragment: TypedDocumentNode<Workflow> = gql`
    fragment Workflow on Workflow {
        identity {
            id
        }
        status
        context {
            entityReference {
                ...Reference
            }
            documentReferences {
                ...Reference
            }
        }
        workflowStepGroups {
            role
            alternateId {
                label
                value
                url
            }
            provider
            messageContent
            isSequential
            workflowSteps {
                workflowParticipant {
                    contacts {
                        ...Contact
                    }
                    alternateId {
                        label
                        value
                        url
                    }
                }
            }
        }
        receivedEvents
    }
    ${ReferenceFragment}
    ${ContactFragment}
`;

export const draftOrderFromBookingQuery: DocumentNode = gql`
    query DraftOrderFromBooking($bookingId: ID!, $orderId: ID) {
        draftOrderFromBooking(bookingId: $bookingId, orderId: $orderId) {
            order {
                ...OrderDetails
            }
            workflow {
                ...Workflow
            }
        }
    }
    ${OrderDetailsFragment}
    ${WorkflowFragment}
`;

export const draftQuoteFromBookingQuery: DocumentNode = gql`
    query DraftQuoteFromBooking($bookingId: ID!, $orderId: ID) {
        draftQuoteFromBooking(bookingId: $bookingId, orderId: $orderId) {
            order {
                ...OrderDetails
            }
            workflow {
                ...Workflow
            }
        }
    }
    ${OrderDetailsFragment}
    ${WorkflowFragment}
`;

export const OrderSummaryForBookingQuery: DocumentNode = gql`
    query OrderSummaryForBooking($bookingId: ID!) {
        orderSummaryForBooking(bookingId: $bookingId) {
            bookingId
            canCreateOrder
            canCreateRevision
            canCreateQuote
            orderStatus
            orders {
                identity {
                    id
                }
                name
                status
                orderNumber
                orderVersion
                orderDate
                needsRevision
                costSummary {
                    base {
                        currencyCode
                        units
                        nanos
                    }
                    discount {
                        currencyCode
                        units
                        nanos
                    }
                    discountedTotal {
                        currencyCode
                        units
                        nanos
                    }
                    taxes {
                        currencyCode
                        units
                        nanos
                    }
                    total {
                        currencyCode
                        units
                        nanos
                    }
                }
            }
            hasConfirmedOrderOrQuote
        }
    }
`;

export const orderQuery: DocumentNode = gql`
    query Order($id: ID!) {
        order(id: $id) {
            order {
                ...OrderDetails
                orderDocumentReference {
                    ...Reference
                }
            }
            workflow {
                ...Workflow
            }
        }
    }
    ${OrderDetailsFragment}
    ${WorkflowFragment}
    ${ReferenceFragment}
`;

export const saveOrderQuery: DocumentNode = gql`
    mutation SaveOrder($orderInput: OrderInput, $workflowInput: WorkflowInput, $noteInput: NoteInput) {
        saveOrder(orderInput: $orderInput, workflowInput: $workflowInput, noteInput: $noteInput) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const saveQuoteQuery: DocumentNode = gql`
    mutation SaveQuote($orderInput: OrderInput, $workflowInput: WorkflowInput, $noteInput: NoteInput) {
        saveQuote(orderInput: $orderInput, workflowInput: $workflowInput, noteInput: $noteInput) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const confirmOrderQuery: DocumentNode = gql`
    mutation ConfirmOrder($orderInput: OrderInput, $workflowInput: WorkflowInput, $noteInput: NoteInput) {
        confirmOrder(orderInput: $orderInput, workflowInput: $workflowInput, noteInput: $noteInput) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const sendQuoteQuery: DocumentNode = gql`
    mutation SendQuote($orderInput: OrderInput, $workflowInput: WorkflowInput, $noteInput: NoteInput) {
        sendQuote(orderInput: $orderInput, workflowInput: $workflowInput, noteInput: $noteInput) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const cancelOrderQuery: DocumentNode = gql`
    mutation CancelOrder($input: CancelOrderInput) {
        cancelOrder(input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const previewOrderQuery: DocumentNode = gql`
    mutation PreviewOrder($orderInput: OrderInput, $workflowInput: WorkflowInput, $noteInput: NoteInput) {
        previewOrder(orderInput: $orderInput, workflowInput: $workflowInput, noteInput: $noteInput) {
            documentReference {
                ...Reference
            }
            workflowReference {
                ...Reference
            }
            orderReference {
                ...Reference
            }
            errors {
                field
                message
            }
        }
    }
    ${ReferenceFragment}
`;

export const previewQuoteQuery: DocumentNode = gql`
    mutation PreviewQuote($orderInput: OrderInput, $workflowInput: WorkflowInput, $noteInput: NoteInput) {
        previewQuote(orderInput: $orderInput, workflowInput: $workflowInput, noteInput: $noteInput) {
            documentReference {
                ...Reference
            }
            workflowReference {
                ...Reference
            }
            orderReference {
                ...Reference
            }
            errors {
                field
                message
            }
        }
    }
    ${ReferenceFragment}
`;

export const useOrderSummaryForBookingData = (bookingId: string) => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const {
        loading: orderSummaryForBookingLoading,
        error: orderSummaryForBookingError,
        refetch: orderSummaryForBookingRefetch,
        data: orderSummaryForBookingData
    } = useQuery(OrderSummaryForBookingQuery, {
        client: orderClient,
        variables: { bookingId: bookingId },
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        fetchPolicy: constants.apolloFetchPolicy,
        skip: !bookingId
    });

    return { orderSummaryForBookingLoading, orderSummaryForBookingData, orderSummaryForBookingRefetch };
};

export const saveAttachmentQuery: DocumentNode = gql`
    mutation SaveAttachments($orderId: ID!, $input: [AttachmentInput]!) {
        saveAttachments(input: $input, orderId: $orderId) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const orderAttachmentDetails: DocumentNode = gql`
    query Attachments($orderId: String!) {
        attachments(orderId: $orderId) {
            name
            size
            type
            createdOn
            createdBy
            entityReference {
                id
                eventId
                uriPrefix
                type
                label
                deleted
            }
        }
    }
`;

export const deleteAttachmentQuery: DocumentNode = gql`
    mutation DeleteAttachments($orderId: ID!, $input: [AttachmentInput]) {
        deleteAttachments(orderId: $orderId, input: $input) {
            id
            errors {
                field
                key
                message
            }
        }
    }
`;

export const useGetAttachments = (orderId: string, skip: boolean) => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const { data, loading, refetch } = useQuery(orderAttachmentDetails, {
        variables: {
            orderId: orderId
        },
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        client: orderClient,
        fetchPolicy: constants.apolloFetchPolicy,
        notifyOnNetworkStatusChange: true,
        skip
    });
    return { data, loading, refetch };
};

export const useDeleteAttachments = () => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const [deleteAttachmentMutation] = useMutation(deleteAttachmentQuery, { client: orderClient });

    const successMessage = useI18n('attachment.delete.success');
    const errorMessage = useI18n('attachment.delete.error');

    const deleteAttachment = (orderId: string, input: AttachmentInput) => {
        return new Promise((resolve, reject) => {
            deleteAttachmentMutation({
                variables: {
                    orderId,
                    input
                },
                context: {
                    headers: {
                        ownerId: activeOrganizationAccount
                    }
                }
            })
                .then((deleteAttachmentResponse) => {
                    if (deleteAttachmentResponse.data.deleteAttachments.errors) {
                        enqueueSnackbar(errorMessage, {
                            variant: 'error'
                        });
                    } else {
                        setTimeout(() => {
                            orderClient.refetchQueries({
                                include: [orderAttachmentDetails]
                            });
                        }, 300);
                        enqueueSnackbar(successMessage, {
                            variant: 'success'
                        });
                    }
                })
                .catch((err) => {
                    enqueueSnackbar(errorMessage, {
                        variant: 'error'
                    });
                });
        });
    };

    return { deleteAttachment };
};

export const useSaveAttachment = () => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const [save, { loading: isSaving }] = useMutation(saveAttachmentQuery, {
        client: orderClient,
        context: { headers: { ownerId: activeOrganizationAccount } }
    });

    const successMessage = useI18n('attachment.save.success');
    const errorMessage = useI18n('attachment.save.error');

    const attachmentSave = (orderId: string, input: AttachmentInput[]) => {
        return new Promise((resolve, reject) => {
            save({
                variables: {
                    orderId: orderId,
                    input: input
                }
            })
                .then((activitySaveResponse) => {
                    if (!activitySaveResponse.data.errors) {
                        enqueueSnackbar(successMessage, {
                            variant: 'success'
                        });
                        resolve(activitySaveResponse);
                    } else {
                        enqueueSnackbar(errorMessage, {
                            variant: 'error'
                        });
                        reject(activitySaveResponse);
                    }
                })
                .catch((err) => {
                    enqueueSnackbar(errorMessage, {
                        variant: 'error'
                    });
                    reject(err);
                })
                .finally(() =>
                    setTimeout(() => {
                        orderClient.refetchQueries({
                            include: [orderAttachmentDetails]
                        });
                    }, 300)
                );
        });
    };

    return { attachmentSave };
};
