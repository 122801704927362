import { useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { Production } from 'sr-types/lib/production/v1/graphql';
import { DateRange, InquiryInput, Location, Reference } from 'sr-types/lib/shortlist/v1/graphql';
import { UserContext } from '../../common/auth/UserContext';
import { enqueueSnackbar } from '../../common/components/Toast';
import { FormContext, FormProvider } from '../../common/form/FormContext';
import FormModal from '../../common/form/FormModal';
import Validator from '../../common/form/Validator';
import I18n from '../../common/i18n/I18n';
import { ValidationRulesType } from '../../production/helpers/productionUtils';
import { getDateRangeListeners, getDateRangeValidators } from '../../supply/booking/ScheduleCalculator';
import {
    ReferenceWithLineItemId,
    SendInquiries,
    checkForEmail,
    client as shortlistClient,
    toInquiryInput
} from '../shortlist';
import InvitePreview from './InvitePreview';
import PersonFormItems from './PersonFormItems';
import { Contact } from 'sr-types/lib/search/v1/graphql';
import InquiryContactDetailsForm from './InquiryContactDetailsForm';
import { useGetPersonDetailsForInquiry } from '../shortlistApi';
import { cloneDeep, isNil } from 'lodash';
import { client, detailsQuery } from '../../production/helpers/production';

export type PeopleType = ReferenceWithLineItemId & {
    contact: Contact & { id?: string };
};

export type InviteFormData = {
    people: PeopleType[];
    resource: Reference;
    resourceCategory: string;
    production: Production;
    role: string;
    productionOptions?: { id: string; label: string; key?: string }[];
    locations?: Location[];
    synopsis?: string;
    dateRange?: DateRange;
    personalizedMessage?: string;
};

type PersonIviteFormProps = {
    isOpen: boolean;
    onClose: () => void;
    data: InviteFormData;
    onSuccess: (editState: InquiryInput[]) => void;
    productionData: Production;
    personData: any;
    isLoading: boolean;
    filter: any;
};

const Form = ({
    isPreview,
    setIsPreview,
    isOpen,
    data,
    onClose,
    sendInvitation,
    isSaving,
    productionData,
    isLoading,
    isEmailModalOpen,
    peopleWithNoContact,
    onClickContinue
}) => {
    const { validation } = useContext(FormContext);
    return (
        <FormModal
            id="crew-invite"
            title={<I18n token={isPreview ? 'shortlist.lineItem.person.preview' : 'shortlist.lineItem.person.form'} />}
            isLoading={isLoading}
            additionalActions={
                isEmailModalOpen
                    ? [
                          {
                              label: <I18n token="organization.add.contact.button.continue" />,
                              onClick: onClickContinue,
                              variant: 'contained',
                              disabled: !validation?.isValid
                          }
                      ]
                    : isPreview
                      ? [
                            {
                                label: <I18n token="shortlist.lineItem.person.back" />,
                                onClick: () => {
                                    setIsPreview(false);
                                },
                                variant: 'text'
                            },
                            {
                                label: <I18n token="shortlist.lineItem.person.sendRequest" />,
                                onClick: sendInvitation,
                                variant: 'contained',
                                disabled: isSaving,
                                loading: isSaving || isLoading
                            }
                        ]
                      : [
                            {
                                label: <I18n token="shortlist.lineItem.person.button.preview" />,
                                onClick: () => {
                                    setIsPreview(true);
                                },
                                variant: 'contained',
                                disabled: !validation?.isValid
                            }
                        ]
            }
            hideDefaultButtons={true}
            isOpen={isOpen}
            onClose={onClose}
        >
            {isNil(isEmailModalOpen) ? (
                <></>
            ) : isEmailModalOpen ? (
                <InquiryContactDetailsForm itemsWithNoContact={peopleWithNoContact} name={'people'} />
            ) : isPreview ? (
                <InvitePreview productionType={productionData?.productionType} />
            ) : (
                <PersonFormItems data={data} />
            )}
        </FormModal>
    );
};

const validationRules: ValidationRulesType = {
    ...getDateRangeValidators('dateRange'),
    people: [Validator.RULES.isRequired],
    production: [Validator.RULES.isRequired],
    role: [Validator.RULES.isRequired],
    locations: [Validator.RULES.isRequired],
    synopsis: [Validator.RULES.isRequired],
    personalizedMessage: [Validator.RULES.isRequired]
};

export default ({ isOpen, onClose, data, onSuccess, productionData, filter }: PersonIviteFormProps) => {
    const { personData, personLoading } = useGetPersonDetailsForInquiry(filter);
    const [editState, setEditState] = useState(data);
    const [isPreview, setIsPreview] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const { activeOrganizationAccount } = useContext(UserContext);
    const [validations, setValidations] = useState({ ...validationRules });
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(null);
    const [peopleWithNoContact, setPeopleWithNoContact] = useState([]);

    const [sendInquiry] = useMutation(SendInquiries, { client: shortlistClient });

    useEffect(() => {
        const values = cloneDeep(data);
        setEditState({ ...values });
    }, [data]);

    useEffect(() => {
        if (personData && personData.search?.hits.items) {
            const { items } = personData.search.hits;
            checkForEmail(items, 'people', editState, setValidations, setIsEmailModalOpen, setPeopleWithNoContact);
        }
    }, [personData, editState?.people]);

    const sendInvitation = () => {
        const inquiryInput: InquiryInput[] = toInquiryInput(editState);

        setIsSaving(true);
        sendInquiry({
            variables: { inquiries: inquiryInput },
            context: {
                headers: {
                    ownerId: activeOrganizationAccount
                }
            }
        })
            .then((data: any) => {
                if (!data?.sendInquiries?.errors) {
                    enqueueSnackbar(<I18n token="form.shortlistLineItem.inquiry.success" />, { variant: 'success' });
                    onSuccess(inquiryInput);
                }
            })
            .catch(() => {
                enqueueSnackbar(<I18n token="form.shortlistLineItem.inquiry.error" />, { variant: 'error' });
            })
            .finally(() => {
                setIsSaving(false);
                setTimeout(() => {
                    client.refetchQueries({
                        include: [detailsQuery]
                    });
                }, 2000);
            });
    };

    const onClickContinue = () => {
        setIsSaving(true);
        setValidations({ ...validationRules });
        setTimeout(() => {
            setIsEmailModalOpen(false);
            setIsSaving(false);
        }, 150);
    };

    return (
        <FormProvider
            state={editState}
            setState={setEditState}
            validationRules={validations}
            listeners={getDateRangeListeners('dateRange')}
        >
            <Form
                isPreview={isPreview}
                setIsPreview={setIsPreview}
                isOpen={isOpen}
                onClose={onClose}
                sendInvitation={sendInvitation}
                isSaving={isSaving}
                productionData={productionData}
                data={data}
                isLoading={personLoading}
                isEmailModalOpen={isEmailModalOpen}
                peopleWithNoContact={peopleWithNoContact}
                onClickContinue={onClickContinue}
            />
        </FormProvider>
    );
};
